import { createApp } from 'vue'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import VueYandexMetrika from 'vue-yandex-metrika' 

import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import 'aos/dist/aos.css'
import 'animate.css';

import JsonExcel from "vue-json-excel3";

const options = {
  color: "#FFD954",
  failedColor: "#000",
  thickness: "3px", 
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
}

// axios.defaults.baseURL = 'https://protender.pro'
axios.defaults.baseURL = 'https://protender.pro'

createApp(App)
.use(VueYandexMetrika, {
    id: 93598106,
    router: router,
    env: process.env.NODE_ENV
}).use(VueProgressBar, options).use(store).component("downloadExcel", JsonExcel).use(router, axios).mount('#app')

