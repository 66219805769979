<template>
   <profile-head></profile-head>
   <main class="main">
      <section class="hero">
         <div class="container">
            <div class="hero__card">
               <ul class="hero__path">
                  <li>
                     <router-link to="/profile/search" class="hero__link">Поиск</router-link>
                     <router-link to="/profile/search" class="hero__link adapt">Поиск</router-link>
                  </li>
                  <!--                   <li>
                     <a href="#" class="hero__link">(ПКР-011625-23) Выполнение работ по
                        разработке
                        проектной…</a>
                     <a href="#" class="hero__link adapt">...</a>
                  </li> -->
                  <li>
                     <a href="#" class="hero__link active">
                        {{ name }}
                     </a>
                     <a href="#" class="hero__link active adapt">
                        {{ name }}
                     </a>
                  </li>
               </ul>
               <div class="hero__title">
                  {{ name }}
               </div>
            </div>
         </div>
      </section>
      <section class="content" v-if="!loader">
         <div class="container">
            <div class="content__row">
               <div class="content__column">
                  <div class="info content__card">
                     <div class="info__title content__title subtitle">
                        Информация
                     </div>
                     <div class="info__subtitle contragent__text light">
                        Отсутствует в едином реестре субъектов малого и среднего
                        предпринимательства
                     </div>
                     <div class="info__grid content__grid">
                        <div class="info__item contragent__text dark">ОГРН</div>
                        <div class="info__item contragent__text light" v-if="contragent.ogrn">
                           {{ contragent.ogrn }}
                        </div>
                        <div class="info__item contragent__text light" v-else>
                           –
                        </div>
                        <div class="info__item contragent__text dark">ИНН</div>
                        <div class="info__item contragent__text light" v-if="contragent.INN">
                           {{ contragent.INN }}
                        </div>
                        <div class="info__item contragent__text light" v-else>
                           –
                        </div>
                        <div class="info__item contragent__text dark">КПП</div>
                        <div class="info__item contragent__text light" v-if="contragent.kpp">
                           {{ contragent.kpp }}
                        </div>
                        <div class="info__item contragent__text light" v-else>
                           –
                        </div>
                        <div class="info__item contragent__text dark">
                           Юридический адрес
                        </div>
                        <div class="info__item contragent__text light" v-if="contragent">
                           {{ contragent.orginfo.address.value }}
                        </div>
                        <div class="info__item contragent__text light" v-else>
                           –
                        </div>
                        <div class="info__item contragent__text dark">
                           Руководитель
                        </div>
                        <div class="info__item">
                           <span class="contragent__text light" v-if="contragent.fio.source">{{ contragent.fio.source
                              }}</span>
                        </div>
                        <div class="info__item contragent__text dark">
                           Уставной капитал
                        </div>
                        <div class="info__item contragent__text light">-</div>
                     </div>
                  </div>
                  <div class="contacts content__card">
                     <div class="contacts__title content__title subtitle">
                        Контактные данные
                     </div>
                     <div class="contacts__grid content__grid">
                        <div class="contacts__item contragent__text dark">
                           Телефон
                        </div>
                        <div class="contacts__item">
                           <a v-for="list in contragent.orginfo.phones?.item" :key="list" :href="'tel:' + list.source"
                              class="contragent__text light">{{ list.source }}
                           </a>
                        </div>
                        <div class="contacts__item contragent__text dark">
                           E-mail
                        </div>
                        <div class="contacts__item contragent__text light" v-if="contragent.emails?.item">
                           <a v-for="list in contragent.emails?.item" :key="list" :href="'mailto:' + list.source"
                              class="contragent__text light">
                              {{ list.source }}
                           </a>
                        </div>
                        <!--                         <div class="contacts__item contragent__text dark">Сайт</div>
                        <div class="contacts__item contragent__text light">
                           <a href="#" class="contragent__text light">website.com</a>
                        </div> -->
                     </div>
                  </div>
                  <div class="taxes content__card">
                     <div class="taxes__part">
                        <div class="taxes__title subtitle">ИФНС</div>
                        <div class="taxes__grid content__grid" v-if="contragent.authorities">
                           <div class="taxes__item contragent__text dark">
                              Налоговый орган
                           </div>
                           <div class="taxes__item contragent__text light">
                              {{ contragent.authorities.fts_registration.name }}
                           </div>
                           <div class="taxes__item contragent__text dark">
                              Адрес
                           </div>
                           <div class="taxes__item contragent__text light" v-if="
                              contragent.authorities.fts_registration.address
                                 .length > 0
                           ">
                              {{
                                 normalizeAddress(contragent.authorities.fts_registration.address)
                              }}
                           </div>
                           <div class="taxes__item contragent__text light" v-else>
                              -
                           </div>
                        </div>
                     </div>
                     <div class="taxes__part">
                        <div class="taxes__title subtitle">ПФР</div>
                        <div class="taxes__grid content__grid" v-if="contragent.authorities">
                           <div class="taxes__item contragent__text dark">
                              Налоговый орган
                           </div>
                           <div class="taxes__item contragent__text light">
                              {{ contragent.authorities.pf.name }}
                           </div>
                           <div class="taxes__item contragent__text dark">
                              Код отделения
                           </div>
                           <div class="taxes__item contragent__text light">
                              {{ contragent.authorities.pf.code }}
                           </div>
                           <div class="taxes__item contragent__text dark">
                              Адрес
                           </div>
                           <div class="taxes__item contragent__text light" v-if="
                              contragent.authorities.pf.address.length > 0
                           ">
                              {{ contragent.authorities.pf.address }}
                           </div>
                           <div class="taxes__item contragent__text light" v-else>
                              -
                           </div>
                        </div>
                     </div>
                     <!--                      <div class="taxes__part">
                        <div class="taxes__title subtitle">ПФР</div>
                        <div class="taxes__grid content__grid">
                           <div class="taxes__item contragent__text dark">Налоговый орган</div>
                           <div class="taxes__item contragent__text light">Инспекция России № 36 по г.Москве</div>
                           <div class="taxes__item contragent__text dark">Рег. номер</div>
                           <div class="taxes__item contragent__text light">770600307277061</div>
                           <div class="taxes__item contragent__text dark">Дата постановки на учет</div>
                           <div class="taxes__item contragent__text light">09.01.2017</div>
                        </div>
                     </div> -->
                  </div>
                  <div class="products content__card">
                     <div class="products__title content__title subtitle">
                        Товары
                     </div>
                     <div class="products__main">
                        <ul class="products__choose">
                           <li>
                              <a href="#productsPurchase"
                                 class="products__link contragent__text light active">Закупка</a>
                           </li>
                           <!--                            <li>
                              <a href="#productsShipment" class="products__link contragent__text light">Поставка</a>
                           </li> -->
                        </ul>
                        <div class="products__tab products-content products-purchase active" id="productsPurchase">
                           <header class="products-content__header">
                              <div class="products-content__price">{{ numberWithSpaces(productsSum) }} ₽</div>
                              <div class="products-content__subtitle contragent__text dark">
                                 Потрачено на закупку товаров и услуг
                              </div>
                           </header>
                           <ul class="products-content__list" style="display: none;">
                              <li class="products-content__item products-item">
                                 <div class="products-item__title contragent__text light">
                                    -
                                 </div>
                                 <!--                                  <div class="products-item__row">
                                    <div class="products-item__count contragent__text dark">Количество: 28</div>
                                    <div class="products-item__price contragent__text light">
                                       <span>44 820 556 ₽</span>
                                       <button></button>
                                    </div>
                                 </div> -->
                              </li>
                              <!--                               <li class="products-content__item products-item">
                                 <div class="products-item__title contragent__text light">Предоставление во временное
                                    пользование мягкого
                                    инвентаря и спецодежды</div>
                                 <div class="products-item__row">
                                    <div class="products-item__count contragent__text dark">Количество: 28</div>
                                    <div class="products-item__price contragent__text light">
                                       <span>44 820 556 ₽</span>
                                       <button></button>
                                    </div>
                                 </div>
                              </li>
                              <li class="products-content__item products-item">
                                 <div class="products-item__title contragent__text light">Предоставление во временное
                                    пользование мягкого
                                    инвентаря и спецодежды</div>
                                 <div class="products-item__row">
                                    <div class="products-item__count contragent__text dark">Количество: 28</div>
                                    <div class="products-item__price contragent__text light">
                                       <span>44 820 556 ₽</span>
                                       <button></button>
                                    </div>
                                 </div>
                              </li>
                              <li class="products-content__item products-item">
                                 <div class="products-item__title contragent__text light">Предоставление во временное
                                    пользование мягкого
                                    инвентаря и спецодежды</div>
                                 <div class="products-item__row">
                                    <div class="products-item__count contragent__text dark">Количество: 28</div>
                                    <div class="products-item__price contragent__text light">
                                       <span>44 820 556 ₽</span>
                                       <button></button>
                                    </div>
                                 </div>
                              </li>
                              <li class="products-content__item products-item">
                                 <div class="products-item__title contragent__text light">Предоставление во временное
                                    пользование мягкого
                                    инвентаря и спецодежды</div>
                                 <div class="products-item__row">
                                    <div class="products-item__count contragent__text dark">Количество: 28</div>
                                    <div class="products-item__price contragent__text light">
                                       <span>44 820 556 ₽</span>
                                       <button></button>
                                    </div>
                                 </div>
                              </li>
                              <li class="products-content__button">
                                 <button class="contragent__text light">Показать еще</button>
                              </li> -->
                           </ul>
                        </div>
                        <div class="products__tab products-content products-shipment" id="productsShipment">
                           <header class="products-content__header">
                              <div class="products-content__price">
                                 999 000 111 ₽
                              </div>
                              <div class="products-content__subtitle contragent__text dark">
                                 Потрачено на закупку товаров и услуг
                              </div>
                           </header>
                           <ul class="products-content__list">
                              <li class="products-content__item products-item">
                                 <div class="products-item__title contragent__text light">
                                    Предоставление во временное пользование
                                    мягкого инвентаря и спецодежды
                                 </div>
                                 <div class="products-item__row">
                                    <div class="products-item__count contragent__text dark">
                                       Количество: 28
                                    </div>
                                    <div class="products-item__price contragent__text light">
                                       <span>44 820 556 ₽</span>
                                       <button></button>
                                    </div>
                                 </div>
                              </li>
                              <li class="products-content__item products-item">
                                 <div class="products-item__title contragent__text light">
                                    Предоставление во временное пользование
                                    мягкого инвентаря и спецодежды
                                 </div>
                                 <div class="products-item__row">
                                    <div class="products-item__count contragent__text dark">
                                       Количество: 28
                                    </div>
                                    <div class="products-item__price contragent__text light">
                                       <span>44 820 556 ₽</span>
                                       <button></button>
                                    </div>
                                 </div>
                              </li>
                              <li class="products-content__item products-item">
                                 <div class="products-item__title contragent__text light">
                                    Предоставление во временное пользование
                                    мягкого инвентаря и спецодежды
                                 </div>
                                 <div class="products-item__row">
                                    <div class="products-item__count contragent__text dark">
                                       Количество: 28
                                    </div>
                                    <div class="products-item__price contragent__text light">
                                       <span>44 820 556 ₽</span>
                                       <button></button>
                                    </div>
                                 </div>
                              </li>
                              <li class="products-content__item products-item">
                                 <div class="products-item__title contragent__text light">
                                    Предоставление во временное пользование
                                    мягкого инвентаря и спецодежды
                                 </div>
                                 <div class="products-item__row">
                                    <div class="products-item__count contragent__text dark">
                                       Количество: 28
                                    </div>
                                    <div class="products-item__price contragent__text light">
                                       <span>44 820 556 ₽</span>
                                       <button></button>
                                    </div>
                                 </div>
                              </li>
                              <li class="products-content__item products-item">
                                 <div class="products-item__title contragent__text light">
                                    Предоставление во временное пользование
                                    мягкого инвентаря и спецодежды
                                 </div>
                                 <div class="products-item__row">
                                    <div class="products-item__count contragent__text dark">
                                       Количество: 28
                                    </div>
                                    <div class="products-item__price contragent__text light">
                                       <span>44 820 556 ₽</span>
                                       <button></button>
                                    </div>
                                 </div>
                              </li>
                              <li class="products-content__button">
                                 <button class="contragent__text light">
                                    Показать еще
                                 </button>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="contracts content__card" v-if="postRait.length != 0 || zakazRait.length != 0">
                     <div class="contracts__title content__title subtitle">
                        Топ по контрактам
                     </div>
                     <div class="contracts__main">
                        <ul class="contracts__choose">
                           <li v-if="postRait.length != 0">
                              <a @click="CurRait('post')" class="contracts__link contragent__text light"
                                 :class="{ active: currrait == 'post' }">
                                 Топ-5 поставщиков
                              </a>
                           </li>
                           <li v-if="zakazRait.length != 0">
                              <a @click="CurRait('zakaz')" class="contracts__link contragent__text light"
                                 :class="{ active: currrait == 'zakaz' }">
                                 Топ-5 заказчиков
                              </a>
                           </li>
                        </ul>
                        <div class="contracts__tub contracts__content contracts-providers"
                           :class="{ active: currrait == 'post' }" id="contractsProviders">
                           <div class="contracts__image" v-if="chartData.datasets[0].data.length">
                              <!-- <img src="@/assets/img/contragent/contracts-content.svg" alt=""> -->
                              <Doughnut height="300" width="300" :data="chartData" :options="chartOptions" />
                           </div>
                           <ul class="contracts__list">
                              <li v-for="(list, index) in postRait" class="contracts-item" :key="index" :class="{
                                 blue: index == 0,
                                 bluewhite: index == 1,
                                 green: index == 2,
                                 orange: index == 3,
                                 yellowtnd: index == 4,
                              }">
                                 <div class="contracts-item__wrapper">
                                    <div class="contracts-item__title">
                                       {{ contragent.name.short }}
                                    </div>
                                    <div class="contracts-item__row">
                                       <div class="contracts-item__price">
                                          {{
                                             numberWithSpaces(list.contract_sum)
                                          }}
                                          ₽
                                       </div>
                                       <div class="contracts-item__count">
                                          {{ list.contract_count }} договор
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div class="contracts__tub contracts__content contracts-clients" id="contractsClients"
                           :class="{ active: currrait == 'zakaz' }">
                           <div class="contracts__image" v-if="chartData2.datasets[0].data.length">
                              <!-- <img src="@/assets/img/contragent/contracts-content.svg" alt=""> -->
                              <Doughnut style="width: 300px; height: 300px" :data="chartData2"
                                 :options="chartOptions2" />
                           </div>
                           <ul class="contracts__list">
                              <li v-for="(list, index) in zakazRait" class="contracts-item" :class="{
                                 blue: index == 0,
                                 bluewhite: index == 1,
                                 green: index == 2,
                                 orange: index == 3,
                                 yellowtnd: index == 4,
                              }" :key="index">
                                 <div class="contracts-item__wrapper">
                                    <div class="contracts-item__title">
                                       {{ contragent.name.short }}
                                    </div>
                                    <div class="contracts-item__row">
                                       <div class="contracts-item__price">
                                          {{
                                             numberWithSpaces(list.contract_sum)
                                          }}
                                          ₽
                                       </div>
                                       <div class="contracts-item__count">
                                          {{ list.contract_count }} договор
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="content__column">
                  <div class="type content__card">
                     <div class="type__title content__title subtitle">
                        Вид деятельности
                     </div>
                     <div class="type__grid content__grid">
                        <div class="type__item contragent__text dark">
                           Основной
                        </div>
                        <div class="type__item contragent__text light" v-if="
                           contragent.okveds.item[0] &&
                           contragent.hasOwnProperty('okveds')
                        ">
                           {{ contragent.okveds.item[0].code }}
                           {{ contragent.okveds.item[0].name }}
                        </div>
                        <div class="type__item contragent__text dark">
                           Дополнительные
                        </div>
                        <div class="type__item contragent__text light" v-if="contragent.hasOwnProperty('okveds')">
                           <p v-for="item in contragent.okveds.item" :key="item">
                              {{ item.code }} {{ item.name }}
                           </p>
                        </div>
                     </div>
                  </div>
                  <div class="complaints content__card">
                     <div class="complaints__header">
                        <div class="complaints__title content__title subtitle">
                           Жалобы в ФАС
                        </div>
                        <div class="complaints__subtitle contragent__text dark">
                           Найдено 0 жалоб
                        </div>
                     </div>
                     <div class="complaints__main">
                        <div class="complaints__item">
                           <span class="contragent__text light">На организацию</span>
                           <span class="subtitle">-</span>
                        </div>
                        <div class="complaints__item">
                           <span class="contragent__text light">От организации</span>
                           <span class="subtitle">-</span>
                        </div>
                     </div>
                  </div>
                  <div class="cases content__card">
                     <div class="complaints__header">
                        <div class="cases__title content__title subtitle">
                           Арбитражные дела
                        </div>
                        <div class="complaints__subtitle contragent__text dark">
                           Найдено 0 судебных дела
                        </div>
                     </div>
                     <div class="complaints__main">
                        <div class="complaints__item">
                           <span class="contragent__text light">На организацию</span>
                           <span class="subtitle">-</span>
                        </div>
                        <div class="complaints__item">
                           <span class="contragent__text light">От организации</span>
                           <span class="subtitle">-</span>
                        </div>
                     </div>
                  </div>
                  <!--                   <div class="management content__card">
                     <div class="management__title content__title subtitle">Исполнительные делопроизводства</div>
                     <div class="management__item">
                        <span class="contragent__text light">Сумма долга</span>
                        <span class="subtitle">400 000 ₽</span>
                     </div>
                  </div> -->
                  <div class="licenses content__card" v-if="licenses.length">
                     <div class="licenses__header">
                        <div class="licenses__title content__title subtitle">
                           Лицензии
                        </div>
                        <div class="licenses__subtitle contragent__text dark">
                           Найдено
                           {{ licenses.length }} лицензии
                        </div>
                     </div>
                     <div class="licenses__main">
                        <div class="licenses-item contragent__text light" v-for="item in licenses" :key="item"
                           :class="{ work: isActiveLicense(item), 'not-work': !isActiveLicense(item) }">
                           <div class=" licenses-item__title">
                              {{ item.issue_authority }}
                           </div>
                           <div class="licenses-item__date">
                              с {{ CorDateLis(item.valid_from) }}
                           </div>
                           <div class="licenses-item__status" v-if="isActiveLicense(item)">Действует</div>
                           <div class="licenses-item__status" v-else>Просрочена</div>

                        </div>
                     </div>
                  </div>
                  <div class="purchases content__card">
                     <div class="purchases__title content__title subtitle">
                        Закупки
                     </div>
                     <div class="purchases__main">
                        <ul class="purchases__choose">
                           <li>
                              <a class="purchases__link contragent__text light active">Заказчик</a>
                           </li>
                           <!--                            <li>
                              <a 
                                 class="purchases__link contragent__text light">Поставщик</a>
                           </li> -->
                        </ul>
                        <div class="purchases__tub purchases__client purchases-content active" id="purchasesClient">
                           <header class="purchases-content__header">
                              <div class="purchases-content__card purchases-card">
                                 <div class="purchases-card__title">
                                    Завершенные закупки
                                 </div>
                                 <div class="purchases-card__count subtitle"
                                    :class="{ dark: Number(zakupki.complete) == 0 }">
                                    {{ zakupki.complete }}
                                 </div>
                                 <div class="purchases-card__link" @click="getSearch(false)" style="cursor: pointer;">
                                    Подробнее ->
                                 </div>
                              </div>
                              <div class="purchases-content__card purchases-card">
                                 <div class="purchases-card__title">
                                    Текущие закупки
                                 </div>
                                 <div class="purchases-card__count subtitle"
                                    :class="{ dark: Number(zakupki.current) == 0 }">
                                    {{ zakupki.current }}
                                 </div>
                                 <div class="purchases-card__link" @click="getSearch" style="cursor: pointer;">
                                    Подробнее ->
                                 </div>
                              </div>
                              <div class="purchases-content__card purchases-card">
                                 <div class="purchases-card__title">
                                    Планируемые закупки
                                 </div>
                                 <div class="purchases-card__count subtitle"
                                    :class="{ dark: Number(zakupki.plan) == 0 }">
                                    {{ zakupki.plan }}
                                 </div>
                                 <div class="purchases-card__link" style="opacity: 0 !important; pointer-events: none">
                                    Подробнее ->
                                 </div>
                              </div>
                           </header>
                           <!-- <main class="purchases-content__main">
                              <div class="purchases-content__title subtitle">Динамика закупок</div>
                              <ul class="purchases-content__list contragent__text">
                                 <li class="yellow">Закупки</li>
                                 <li class="grey">Планы</li>
                                 <li class="green">Договоры</li>
                              </ul>
                              <div class="purchases-content__graphs">
                                 <div class="purchases-graph purchases-graph_2016">
                                    <div class="purchases-graph__lines">
                                       <div class="purchases-graph__line yellow"></div>
                                       <div class="purchases-graph__line grey"></div>
                                       <div class="purchases-graph__line green"></div>
                                    </div>
                                    <div class="purchases-graph__year">2016</div>
                                 </div>
                                 <div class="purchases-graph purchases-graph_2017">
                                    <div class="purchases-graph__lines">
                                       <div class="purchases-graph__line yellow"></div>
                                       <div class="purchases-graph__line grey"></div>
                                       <div class="purchases-graph__line green"></div>
                                    </div>
                                    <div class="purchases-graph__year">2017</div>
                                 </div>
                                 <div class="purchases-graph purchases-graph_2018">
                                    <div class="purchases-graph__lines">
                                       <div class="purchases-graph__line yellow"></div>
                                       <div class="purchases-graph__line grey"></div>
                                       <div class="purchases-graph__line green"></div>
                                    </div>
                                    <div class="purchases-graph__year">2018</div>
                                 </div>
                                 <div class="purchases-graph purchases-graph_2019">
                                    <div class="purchases-graph__lines">
                                       <div class="purchases-graph__line yellow"></div>
                                       <div class="purchases-graph__line grey"></div>
                                       <div class="purchases-graph__line green"></div>
                                    </div>
                                    <div class="purchases-graph__year">2019</div>
                                 </div>
                                 <div class="purchases-graph purchases-graph_2020">
                                    <div class="purchases-graph__lines">
                                       <div class="purchases-graph__line yellow"></div>
                                       <div class="purchases-graph__line grey"></div>
                                       <div class="purchases-graph__line green"></div>
                                    </div>
                                    <div class="purchases-graph__year">2020</div>
                                 </div>
                                 <div class="purchases-graph purchases-graph_2021">
                                    <div class="purchases-graph__lines">
                                       <div class="purchases-graph__line yellow"></div>
                                       <div class="purchases-graph__line grey"></div>
                                       <div class="purchases-graph__line green"></div>
                                    </div>
                                    <div class="purchases-graph__year">2021</div>
                                 </div>
                                 <div class="purchases-graph purchases-graph_2022">
                                    <div class="purchases-graph__lines">
                                       <div class="purchases-graph__line yellow"></div>
                                       <div class="purchases-graph__line grey"></div>
                                       <div class="purchases-graph__line green"></div>
                                    </div>
                                    <div class="purchases-graph__year">2022</div>
                                 </div>
                                 <div class="purchases-graph purchases-graph_2023">
                                    <div class="purchases-graph__lines">
                                       <div class="purchases-graph__line yellow"></div>
                                       <div class="purchases-graph__line grey"></div>
                                       <div class="purchases-graph__line green"></div>
                                    </div>
                                    <div class="purchases-graph__year">2023</div>
                                 </div>
                              </div>
                           </main> -->
                        </div>
                        <div class="purchases__tub purchases__supplier purchases-content" id="purchasesSupplier"></div>
                     </div>
                  </div>
                  <div class="economic content__card" v-if="false">
                     <div class="founders economic__content">
                        <div class="founders__title content__title subtitle">
                           Учредители
                        </div>
                        <div class="founders__column">
                           <div class="founders__item contragent__text light">
                              <div class="founders__name">-</div>
                              <!--                               <div class="founders__numbers">
                                 <div class="founders__price">500 000 ₽</div>
                                 <div class="founders__procents">50%</div>
                              </div> -->
                           </div>
                        </div>
                     </div>
                     <!--                      <div class="economic__line"></div>
                     <div class="communications economic__content">
                        <div class="communications__title content__title subtitle">Связи</div>
                        <div class="communications__row">
                           <div class="communications__item">
                              <span class="contragent__text light">Дочерние организации</span>
                              <span class="subtitle">12</span>
                           </div>
                           <div class="communications__item">
                              <span class="contragent__text light">Правопредшественники</span>
                              <span class="subtitle">12</span>
                           </div>
                           <div class="communications__item">
                              <span class="contragent__text light">Правопреемники</span>
                              <span class="subtitle">0</span>
                           </div>
                        </div>
                     </div> -->
                     <!-- <div class="economic__line"></div> -->
                     <!--                      <div class="branches economic__content">
                        <div class="branches__title content__title subtitle">Филлиалы и представительства</div>
                        <div class="branches__column">
                           <div class="branches__item">
                              <span class="contragent__text light">ОАО "Азнакаевский хлебокомбинат"</span>
                              <span class="small">Адрес регистрации: г. Москва, ул. Конституции, д.11, офис
                                 345</span>
                           </div>
                           <div class="branches__item">
                              <span class="contragent__text light">ОАО "Азнакаевский хлебокомбинат"</span>
                              <span class="small">Адрес регистрации: г. Москва, ул. Конституции, д.11, офис
                                 345</span>
                           </div>
                        </div>
                     </div> -->
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="content" v-else>
         <div class="loader">
            <div class="lds-ring">
               <div></div>
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </section>
      <section class="activity" v-if="!loader">
         <div class="container">
            <div class="activity__card">
               <div class="activity__title subtitle">
                  Закупочная деятельность заказчика
               </div>
               <div class="activity__row">
                  <div class="activity__image">
                     <picture>
                        <source srcset="@/assets/img/contragent/ativity.webp" type="image/webp" />
                        <img src="@/assets/img/contragent/ativity.png" alt="" />
                     </picture>
                  </div>
                  <ul class="activity__info">
                     <li class="activity-item activity-item_purchases">
                        <div class="activity-item__wrapper">
                           <div class="activity-item__count subtitle" v-if="contragent.CustomersKolvoContracts != 0">
                              {{ contragent.CustomersKolvoContracts }}
                           </div>
                           <div class="activity-item__count subtitle" v-else>
                              -
                           </div>
                           <div class="activity-item__desc contragent__text light">
                              Размещено закупок
                           </div>
                        </div>
                     </li>
                     <li class="activity-item activity-item_contracts">
                        <div class="activity-item__wrapper">
                           <div class="activity-item__count subtitle" v-if="contragent.SuppliersKolvoContracts != 0">
                              {{ contragent.SuppliersKolvoContracts }}
                           </div>
                           <div class="activity-item__count subtitle" v-else>
                              -
                           </div>
                           <div class="activity-item__desc contragent__text light">
                              Заключено контрактов
                           </div>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<style type="scss"></style>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";
import moment from "moment";
import ProfileHeader from "@/components/Header/ProfileHeader";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";
import { mapMutations } from "vuex";
ChartJS.register(ArcElement, Tooltip, Legend);
export default {
   name: "ContragentDetail",
   directives: { mask },
   data() {
      return {
         contragent: {},
         zakazRait: [],
         postRait: [],
         id: 0,

         dateEnd: 0,

         loader: true,
         chartData: {
            datasets: [
               {
                  backgroundColor: [
                     "#0B62D8",
                     "#81B6DD",
                     "#0A9359",
                     "#D9952E",
                     "#FFD954",
                  ],
                  borderColor: "#1E2024",
                  data: [],
               },
            ],
         },
         chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
         },
         chartData2: {
            datasets: [
               {
                  backgroundColor: [
                     "#0B62D8",
                     "#81B6DD",
                     "#0A9359",
                     "#D9952E",
                     "#FFD954",
                  ],
                  borderColor: "#1E2024",
                  data: [],
               },
            ],
         },
         chartOptions2: {
            responsive: true,
            maintainAspectRatio: false,
         },
         currrait: "post",

         zakupki: {}
      };
   },
   mounted() { },
   methods: {
      ...mapMutations(["CHANGE_SEARCH_DATA"]),
      isActiveLicense(license) {
         if (typeof (license.valid_to) !== 'string') return false;
         return license.valid_to > license.valid_from ? true : false;
      },
      normalizeAddress(string) {
         let str = string.replaceAll(",,", ',')
         let arr = str.split('')
         arr[0] == ',' ? arr.shift() : arr;
         arr[arr.length - 1] == ',' ? arr.pop() : arr;
         return arr.join('')
      },
      makeCircle(value) {
         value.forEach((item) => {
            console.log(item);
            this.chartData.datasets[0].data.push(item.contract_sum);
         });
      },
      makeSecondCircle(value) {
         value.forEach((item) => {
            this.chartData2.datasets[0].data.push(item.contract_sum);
         });
      },
      CorDateLis(date) {
         return moment.unix(date / 1000).format("DD.MM.YYYY");
      },
      CorDate(date) {
         return moment(String(date), "DD-MM-YYYY").format("DD.MM.YYYY");
      },
      GoToRef(val) {
         this.$refs[val].scrollIntoView({ behavior: "smooth" });
      },
      numberWithSpaces(x) {
         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      async GetContragent(value) {
         await axios
            .get(`/api/v1/get/contragent/${value}/`)
            .then((response) => {
               this.contragent = response.data[0];
               this.loader = false;
               this.GetZakaz(value);
               this.GetPost(value);
               // console.log(response.data[0]);
               console.log(new Date(1558483200000));
               // console.log("test contragent", response.data[0]);
               this.getZakupki()

            })
            .catch((error) => {
               console.log(error);
            });
      },
      async getZakupki() {
         let { data } = await axios(`/api/v1/get/analyze/contragent/${this.id}/tenders/stats/`)
         console.log('plan zakup', data);
         this.zakupki = data
      },
      async getSearch(actual = true) {
         let object = {
            kwords: this.id,
         }
         if (actual) object.actual = 1
         if (!actual) object.not_actual = 1
         this.CHANGE_SEARCH_DATA(object)
         this.$router.push('/profile/search?refresh=true')
         // let { data } = await axios(`/api/v1/get/analyze/contragent/${this.id}/tenders/current/1/`)
         // console.log('current', data);
      },
      async GetZakaz(value) {
         await axios
            .get(`/api/v1/get/zakaz/years/${value}/`)
            .then((response) => {
               this.zakazRait = response.data;
               this.makeSecondCircle(this.zakazRait);

               // console.log(response.data)
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetPost(value) {
         await axios
            .get(`/api/v1/get/post/years/${value}/`)
            .then((response) => {
               this.postRait = response.data;
               this.makeCircle(this.postRait);
               if (response.data.length == 0) this.currrait = "zakaz";
            })
            .catch((error) => {
               console.log(error);
            });
      },
      CurRait(value) {
         this.currrait = value;
      },
   },
   components: {
      "profile-head": ProfileHeader,
      Doughnut,
   },
   computed: {
      licenses() {
         if (!this.contragent.hasOwnProperty('licenses')) return []
         let isArr = Array.isArray(this.contragent.licenses.item);
         if (isArr) {
            return this.contragent.licenses.item
         }
         else {
            return [this.contragent.licenses.item]
         }
      },
      productsSum() {
         let res = 0;
         this.zakazRait.forEach(item => {
            res += Number(item.contract_sum)
         })
         return res
      },
      name() {
         if (this.contragent?.name?.short?.length) {
            return this.contragent?.name?.short
         } else {
            return this.contragent?.name?.full
         }
      }
   },
   watch: {},
   created() {
      moment.locale("ru");
      this.id = this.$route.params.id;
      this.GetContragent(this.$route.params.id);
   },
};
</script>
