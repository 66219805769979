<template>
   <profile-head></profile-head>
   <main class="main">
      <section class="hero">
         <div class="container">
            <div class="hero__card">
               <div class="hero__title title">Видеокурс по закупкам</div>
            </div>
         </div>
      </section>
      <section class="videos">
         <div class="container">
            <div class="videos__grid">
               <a
                  v-for="list in videos"
                  @click="openVideo(list)"
                  class="video modal__link"
               >
                  <div class="video__preview">
                     <img
                        v-if="list.get_preview"
                        :src="list.get_preview"
                        alt=""
                     />
                     <div class="time" v-if="list.duration">
                        {{ list.duration }}
                     </div>
                  </div>
                  <div class="video__title" v-if="list.name">
                     {{ list.name }}
                  </div>
               </a>
            </div>
         </div>
      </section>
      <div
         class="video-modal modal"
         id="videoModal"
         :class="{ open: modal }"
         @click="closeModal()"
      >
         <div class="modal__content video-modal__content" @click.stop="">
            <div
               class="modal__close video-modal__close"
               @click="closeModal()"
            ></div>
            <div class="fullIframe" v-if="iframe != ''" v-html="iframe"></div>
         </div>
      </div>
   </main>
</template>

<style type="scss">
.fullIframe {
   width: 100% !important;
   height: 100% !important;
}

.video-modal__close {
   cursor: pointer;
}
</style>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";

import ProfileHeader from "@/components/Header/ProfileHeader";

export default {
   name: "VideoCourses",
   directives: { mask },
   data() {
      return {
         modal: false,
         videos: [],

         iframe: "",
      };
   },
   mounted() {},
   methods: {
      GoToRef(val) {
         this.$refs[val].scrollIntoView({ behavior: "smooth" });
      },
      openVideo(list) {
         this.modal = true;

         this.iframe = list.description;
      },
      closeModal() {
         this.modal = false;
         this.iframe = "";
      },
      async GetAllVideos() {
         await axios
            .get("/api/v1/get/videos/")
            .then((response) => {
               this.videos = response.data;
               console.log(response.data);
            })
            .catch((error) => {
               console.log(error);
            });
      },
   },
   components: {
      "profile-head": ProfileHeader,
   },
   watch: {},
   created() {
      this.GetAllVideos();
   },
};
</script>
