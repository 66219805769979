<template>
    <div class="authorization">
      <div class="forger2 modal authorization__modal" id="forget2" :class="{'open': modal}">
         <div class="forget2__content modal__content authorization__content">
            <div class="forget2__row authorization__row">
               <div class="forget1__title authorization__title">{{ subject }}</div>
               <a @click="nextTip(false)" class="forget1__close modal__close authorization__close"></a>
            </div>
            <div class="forget2__text">
               {{ description }}
            </div>
            <a @click="nextTip(false)" class="sign__btn btn gold modal__close">Закрыть</a>
         </div>
      </div>
    </div>
</template>

<style scoped>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import moment from 'moment';

    export default {
        name: "SuccessModal",
        props: {
            modal: Boolean,
            subject: String,
            description: String,
        },
        components: {

        },
        data() {
            return {
                loader: false,
            }
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {

        },
        methods: {
            nextTip(value) {
              this.$emit('close', value)
            },
        },
        created() {

        },
        watch: {

        },
   }
</script>