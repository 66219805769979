<template>
   <div class="changeMail modal authorization authorization__modal" :class="{ open: modal }"
      @mousedown="onMouseDownHandler" @mouseup="onMouseUpHandler">
      <div class="forget1__content modal__content authorization__content" @click.stop="">
         <div class="forget1__row authorization__row">
            <div class="forget1__title authorization__title">
               Заявка на сопровождение
            </div>
            <a @click="$emit('close', false)" class="forget1__close modal__close authorization__close"></a>
         </div>
         <form @submit.prevent="SendEscort()" class="forget1__main">
            <div class="forget1__input authorization__input">
               <span>ФИО</span>
               <input v-model="fio" type="text" />
            </div>
            <div class="forget1__input authorization__input">
               <span>ИНН</span>
               <input v-model="inn" type="tel" v-mask="'##################'" />
            </div>
            <div class="forget1__input authorization__input">
               <span>Телефон</span>
               <input v-model="phone" type="tel" v-mask="'+# (###) ###-##-##'" />
            </div>
            <div class="forget1__input authorization__input">
               <span>E-mail</span>
               <input v-model="email" type="email" />
            </div>
            <div class="forget1__input authorization__input" v-if="tender">
               <span>Ссылка на тендер</span>
               <router-link :to="'/profile/tender/' + $route?.params.id" target="_blank">{{
                  "protender.pro/profile/tender/" + $route?.params.id
               }}</router-link>
            </div>
            <div class="template-modal__not checkbox__item mIn">
               <input v-model="tgw" type="checkbox" id="templateModalNot" />
               <label for="templateModalNot"></label>
               <p class="search__text">Связаться в WhatsApp, Telegram</p>
            </div>
            <div class="forget1__buttons">
               <a @click="SendEscort()" v-if="fio != '' && inn != '' && email != ''"
                  class="sign__btn btn gold modal__link">
                  Отправить</a>
               <a class="sign__btn btn gold modal__link pol-opacity_btn" v-else>Отправить</a>
            </div>
         </form>
      </div>
   </div>
</template>

<style scoped lang="scss">
.authorization__input {
   a {
      background-color: #2d2f33;
      height: 48px;
      padding: 12px;
      border-radius: 8px;
      color: #fff;
      display: flex;
      align-items: center;
      overflow: hidden;
   }
}

/*.loader__pass h2 {
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    margin: 35px 0 15px;
    color: #1B1B1E;
}*/
</style>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";
import { toast } from "bulma-toast";
import moment from "moment";

export default {
   name: "Escort",
   directives: { mask },
   props: {
      modal: {
         type: Boolean,
         default: false,
      },
      tender: {
         type: Boolean,
         default: false,
      },
      userOn: {
         type: Boolean,
         default: false,
      },
   },
   components: {},
   data() {
      return {
         fio: "",
         inn: "",
         phone: "+7",
         tgw: false,
         email: "",

         subject: "",
         description: "",
         mouseTarget: null,
      };
   },
   beforeCreate() {
      this.$store.commit("initializeStore");
   },
   methods: {
      onMouseDownHandler(e) {
         this.mouseTarget = e.target;
      },
      onMouseUpHandler(e) {
         if (
            e.target == this.mouseTarget &&
            e.target.classList.contains("modal")
         ) {
            this.$emit("close", false);
         } else {
            this.mouseTarget = null;
         }
      },
      async GetMeInfo() {
         await axios
            .get("/api/v1/me/")
            .then((response) => {
               this.fio = response.data.fio;
               this.inn = response.data.inn;
               this.phone = response.data.phone;
               this.email = response.data.email;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async SendEscort() {
         const DataNew = {
            fio: this.fio,
            inn: this.inn,
            phone: this.phone,
            tgw: this.tgw,
            email: this.email,
            tender: this.$route?.params.id,
            date_added_public: new Date()
         };

         await axios
            .post("/api/v1/post/escort/", DataNew)
            .then((response) => {
               toast({
                  message: "Успешно отправлена",
                  type: "is-success",
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: "bottom-right",
               });

               this.fio = "";
               this.inn = "";
               this.phone = "";
               this.tgw = "";
               this.email = "";

               this.$emit("close", false);

               console.log(response.data);
            })
            .catch((error) => {
               console.log(error);
            });
      },
   },
   created() {
      if (this.userOn) {
         this.GetMeInfo();
      }
   },
   watch: {
      userOn(value) {
         if (value) this.GetMeInfo();
      },
   },
};
</script>
