import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

// Home

import HomeView from '../views/HomeView.vue'

// Profile

import ProfileView from '../views/Acc/Profile.vue'
import FavoriteView from '../views/Acc/Favorites.vue'
import SearchView from '../views/Acc/Search.vue'
import VideoCourses from '../views/Acc/VideoCourses.vue'
import Instructions from '../views/Acc/Instructions.vue'
import Escort from '../views/Acc/Escort.vue'

import InstructionMain from '../views/OtherPage/Instruction.vue'
import EscortMain from '../views/OtherPage/Escort.vue'

import TenderDetail from '../views/Acc/Tender/TenderDetail.vue'
import ContragentDetail from '../views/Acc/Tender/ContragentDetail.vue'

const routes = [
   {
      path: '/',
      name: 'home',
      component: HomeView
   },
   {
      path: '/profile',
      name: 'Profile',
      component: ProfileView,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/profile/favorite',
      name: 'Favorites',
      component: FavoriteView,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/profile/search',
      name: 'Search',
      component: SearchView,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/profile/videos',
      name: 'VideoCourses',
      component: VideoCourses,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/profile/instructions',
      name: 'Instructions',
      component: Instructions,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/profile/tender/:id',
      name: 'TenderDetail',
      component: TenderDetail,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/profile/contragent/:id',
      name: 'ContragentDetail',
      component: ContragentDetail,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/profile/escort',
      name: 'Escort',
      component: Escort,
      meta: {
         requireLogin: true,
      }
   },
   {
      path: '/instructions',
      name: 'InstructionMain',
      component: InstructionMain,
   },
   {
      path: '/escort',
      name: 'EscortMain',
      component: EscortMain
   },
]

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes,
   scrollBehavior() {
      window.scrollTo(0, 0);
      // document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
   }
})

router.beforeEach((to, from, next) => {
   if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
      next({ name: 'home' });
   } else {
      next()
   }
})

export default router
