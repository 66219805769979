<template>
   <profile-head></profile-head>
   <main class="main">
      <section class="hero">
         <div class="container">
            <div class="hero__card escort__card">
               <div class="hero__title title">
                  Поможем пройти все этапы торгов: <br />
                  от анализа тендера до заключения контракта
               </div>
               <footer class="hero__footer">
                  <div class="hero__main">
                     <div class="hero__price title">15 000 ₽</div>
                     <div class="hero__desc subtitle">
                        стоимость сопровождения 1 закупки с регистрацией на 8
                        электронных площадках
                     </div>
                  </div>
                  <button class="hero__btn btn" @click="escrotForm = true">
                     Подключить сопровождение
                  </button>
               </footer>
            </div>
         </div>
      </section>
      <section class="preferences" data-aos="fade-up" data-aos-duration="1500">
         <div class="container">
            <div class="preferences__row">
               <div class="preferences__card escort__card">
                  <header class="preferences__header">
                     <div class="preferences__title subtitle">
                        Минимизация <br />
                        рисков
                     </div>
                     <div class="preferences__image">
                        <img src="@/assets/img/icons/escort1.svg" alt="" />
                     </div>
                  </header>
                  <div class="preferences__text">
                     Обеспечиваем бизнесу профессиональную поддержку на каждом
                     этапе участия в тендере
                  </div>
               </div>
               <div class="preferences__card escort__card">
                  <header class="preferences__header">
                     <div class="preferences__title subtitle">
                        Конкурентное <br />
                        преимущество
                     </div>
                     <div class="preferences__image">
                        <img src="@/assets/img/icons/escort2.svg" alt="" />
                     </div>
                  </header>
                  <div class="preferences__text">
                     Помогаем создать конкурентоспособное предложение, которое
                     максимально соответствует требованиям заказчика
                  </div>
               </div>
               <div class="preferences__card escort__card">
                  <header class="preferences__header">
                     <div class="preferences__title subtitle">
                        Экономия времени <br />
                        и ресурсов
                     </div>
                     <div class="preferences__image">
                        <img src="@/assets/img/icons/escort3.svg" alt="" />
                     </div>
                  </header>
                  <div class="preferences__text">
                     Экономим ваше время и ресурсы на подготовку и участие в
                     закупках, чтобы вы могли сосредоточиться на своих основных
                     задачах
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="plan" data-aos="fade-up" data-aos-duration="1500">
         <div class="container">
            <div class="plan__card escort__card">
               <div class="plan__title title">
                  Тендерное сопровождение включает полный комплекс услуг,
                  предоставляемых опытными специалистами с целью увеличения
                  вашей прибыли
               </div>
               <div class="plan__grid">
                  <div class="plan-item">
                     <header class="plan-item__header">
                        <div class="plan-item__image"></div>
                        <div class="plan-item__number subtitle">01</div>
                     </header>
                     <main class="plan-item__text">
                        Подбор закупок по параметрам, анализ документации,
                        оценка рисков
                     </main>
                  </div>
                  <div class="plan-item">
                     <header class="plan-item__header">
                        <div class="plan-item__image"></div>
                        <div class="plan-item__number subtitle">02</div>
                     </header>
                     <main class="plan-item__text">
                        Взаимодействие с заказчиком и площадкой от лица
                        поставщика на предмет получения разъяснений
                     </main>
                  </div>
                  <div class="plan-item">
                     <header class="plan-item__header">
                        <div class="plan-item__image"></div>
                        <div class="plan-item__number subtitle">03</div>
                     </header>
                     <main class="plan-item__text">
                        Подготовка документации для участия в закупочных
                        процедурах
                     </main>
                  </div>
                  <div class="plan-item">
                     <header class="plan-item__header">
                        <div class="plan-item__image"></div>
                        <div class="plan-item__number subtitle">04</div>
                     </header>
                     <main class="plan-item__text">
                        Подгрузка от лица поставщика полного пакета документов
                        на площадку
                     </main>
                  </div>
                  <div class="plan-item">
                     <header class="plan-item__header">
                        <div class="plan-item__image"></div>
                        <div class="plan-item__number subtitle">05</div>
                     </header>
                     <main class="plan-item__text">
                        Участие в аукционах от лица поставщика
                     </main>
                  </div>
                  <div class="plan-item">
                     <header class="plan-item__header">
                        <div class="plan-item__image"></div>
                        <div class="plan-item__number subtitle">06</div>
                     </header>
                     <main class="plan-item__text">
                        Заключение договоров с соблюдением сроков и подгрузкой
                        необходимых документов
                     </main>
                  </div>
                  <div class="plan-item">
                     <header class="plan-item__header">
                        <div class="plan-item__image"></div>
                        <div class="plan-item__number subtitle">07</div>
                     </header>
                     <main class="plan-item__text">
                        Актирование в ЕИС в случае выигрыша (по 44-ФЗ),
                        настройка рабочего места для участия в торгах и
                        аккредитация на 8 электронных площадках
                     </main>
                  </div>
                  <div class="plan-item">
                     <header class="plan-item__header">
                        <div class="plan-item__image"></div>
                        <div class="plan-item__number subtitle">08</div>
                     </header>
                     <main class="plan-item__text">
                        Защита интересов и составление обоснованных жалоб в ФАС
                        (при необходимости).
                     </main>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="benefit">
         <div class="container">
            <div class="benefit__row">
               <div class="benefit__card escort__card benefit__main">
                  <div class="benefit__title title">
                     Какую выгоду вы получаете
                  </div>
                  <div class="benefit__desc">
                     Обучение начальным навыкам участия в тендерах занимает от
                     120 академических часов. Средние затраты на одного
                     специалиста достаточной для участия квалификации в месяц
                     около 100 000 ₽ (с налогами, страховыми взносами и рабочим
                     местом).
                  </div>
               </div>
               <div class="benefit__card escort__card independent">
                  <div class="independent__part">
                     <div class="independent__title subtitle">
                        Самостоятельное участие
                     </div>
                     <div class="independent__main">
                        <div class="independent__text">
                           <div class="independent__price">~100 000 ₽</div>
                           <div class="independent__desc">
                              с налогами, страховыми взносами и рабочим местом
                           </div>
                        </div>
                        <div class="independent__line">
                           <div class="fill"></div>
                        </div>
                     </div>
                  </div>
                  <div class="independent__tender">
                     <div class="independent__title subtitle">
                        Тендерное сопровождение
                     </div>
                     <div class="independent__main">
                        <div class="independent__text">
                           <div class="independent__price less">30 000 ₽</div>
                           <div class="independent__desc">
                              пакет сопровождения в расчете на месяц
                           </div>
                        </div>
                        <div class="independent__line">
                           <div class="fill"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <div ref="question" style="transform: translateY(-100px)"></div>
      <div class="questions">
         <div class="container">
            <form @submit.prevent="SendFormBack()" class="questions__card escort__card">
               <header class="questions__header">
                  <div class="questions__title">Остались вопросы?</div>
                  <div class="questions__subtitle">
                     Задайте нам вопрос, мы ответим вам в самое ближайшее время
                  </div>
               </header>
               <main class="questions__main">
                  <div class="questions__input">
                     <span class="questions__subtitle">Ваш E-mail</span>
                     <input v-model="subject" type="text" />
                  </div>
                  <div class="questions__input">
                     <span class="questions__subtitle">Комментарий</span>
                     <textarea v-model="description" type="text" cols="30" rows="10"></textarea>
                  </div>
               </main>
               <footer class="questions__footer">
                  <button class="questions__btn btn" type="submit">
                     Отправить
                  </button>
                  <!--                   <div class="questions__agreement questions__subtitle">Нажимая кнопку «Отправить», вы даете согласие
                     на обработку
                     персональных данных</div> -->
               </footer>
            </form>
         </div>
      </div>
   </main>

   <EscortModal :modal="escrotForm" :userOn="true" @close="escrotForm = $event" />
</template>

<style type="scss">
.pol-opacity_btn {
   opacity: 0.4;
}

.mIn {
   margin-bottom: 24px !important;
}
</style>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";
import { toast } from "bulma-toast";

import ProfileHeader from "@/components/Header/ProfileHeader";

import EscortModal from "@/components/Modal/Escort";

import AOS from "aos";
import { onMounted } from "@vue/runtime-core";

export default {
   name: "Escort",
   directives: { mask },
   data() {
      return {
         fio: "",
         inn: "",
         phone: "+7",
         tgw: false,
         email: "",
         escrotForm: false,

         subject: "",
         description: "",
      };
   },
   setup() {
      onMounted(() => {
         AOS.init();
      });
   },
   mounted() {
      if (this.$route.query.redirect) {
         setTimeout(() => {
            if (!this.$refs[this.$route.query.redirect]) {
               return;
            }
            this.$refs[this.$route.query.redirect].scrollIntoView({
               behavior: "smooth",
               block: "start",
            });
         }, 100);
      }

      if (this.$route.query.modal) this.escrotForm = true;
   },
   methods: {
      async SendFormBack() {
         const DataNew = {
            subject: this.subject,
            description: this.description,
            date_added_public: new Date()
         };

         await axios
            .post("/api/v1/post/form/back/", DataNew)
            .then((response) => {
               toast({
                  message: "Успешно отправлена",
                  type: "is-success",
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: "bottom-right",
               });

               this.subject = "";
               this.description = "";

               console.log(response.data);
            })
            .catch((error) => {
               console.log(error);
            });
      },

      GoToRef(val) {
         if (!this.$refs[val]) {
            return;
         }
         this.$refs[val].scrollIntoView({ behavior: "smooth" });
      },
   },
   components: {
      "profile-head": ProfileHeader,
      EscortModal,
   },
   created() { },
   watch: {
      "$route.query.redirect": {
         handler(value) {
            setTimeout(() => {
               if (!this.$refs[value]) {
                  return;
               }
               this.$refs[value].scrollIntoView({ behavior: "smooth" });
            }, 500);
         },
         deep: true,
         immediate: true,
      },
   },
};
</script>
