<template>
   <profile-head></profile-head>
   <main class="main">
      <section class="hero">
         <div class="container">
            <div class="hero__card">
               <div class="hero__title title">Инструкции</div>
               <button @click="GoLinkRef('question')" class="hero__button btn">
                  Задать вопрос
               </button>
            </div>
         </div>
      </section>
      <section class="content">
         <div class="container">
            <div class="content__row">
               <aside class="content__aside content-aside" :class="{ active: aside }">
                  <div class="content-aside__adapt aside-adapt" @click="asideActivate()">
                     <div class="aside-adapt__title">
                        <span class="aside-adapt__title_span">
                           Все инструкции
                        </span>
                        <!-- <span>...</span> -->
                     </div>
                     <div class="aside-adapt__button">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M4.41113 8.92265L5.58964 7.74414L10.0004 12.1549L14.4111 7.74414L15.5896 8.92265L10.0004 14.5119L4.41113 8.92265Z"
                              fill="white" />
                        </svg>
                     </div>
                  </div>
                  <div class="content-aside__row">
                     <div class="content-aside__title instructions__title">
                        Инструкции
                     </div>
                     <div class="content-aside__close" @click="asideActivate()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M3.29291 19.293L19.2929 3.29297L20.7071 4.70718L4.70712 20.7072L3.29291 19.293Z"
                              fill="white" />
                           <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M20.7071 19.293L4.70712 3.29297L3.29291 4.70718L19.2929 20.7072L20.7071 19.293Z"
                              fill="white" />
                        </svg>
                     </div>
                  </div>
                  <div class="content-aside__wrapper">
                     <ul class="content-aside__list">
                        <li class="content-aside__item" v-for="(list, index) in instructions" :key="index">
                           <a @click="GoToRef(index)" class="content-aside__link instructions__text dark"
                              :class="{ active: index == curSubj }">{{ list.subject }}</a>
                        </li>
                     </ul>
                  </div>
               </aside>
               <div class="content__column" ref="instruction">
                  <div id="instructionTemplateSettings" class="instructions__card template-settings"
                     v-for="(list, index) in instructions" :key="index" style="position: relative"
                     :ref="'listinst' + index">
                     <div style="position: absolute; top: -80px; left: 0" :ref="'listinstref' + index"></div>
                     <div class="template-settings__title instructions__title">
                        {{ list.subject }}
                     </div>
                     <div class="template-settings__row">
                        <div class="template-settings__text instructions__text light instructions__state">
                           <p v-html="list.description"></p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<style type="scss"></style>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";

import ProfileHeader from "@/components/Header/ProfileHeader";

export default {
   name: "Instructions",
   directives: { mask },
   data() {
      return {
         phone: "+7",
         email: "",

         aside: false,

         instructions: [],

         positionTop: 0,

         curSubj: 0,
      };
   },
   mounted() {
      this.GetAllInstruction();

      window.addEventListener("scroll", this.onScroll);
   },
   unmounted() {
      console.log('unmount');
      window.removeEventListener("scroll", this.onScroll);
   },
   methods: {
      onScroll(e) {
         this.positionTop = window.top.scrollY;
         console.log(this.positionTop);

         for (var i = 0; i < this.instructions.length; i++) {
            if (
               parseInt(this.$refs[`listinst${i}`][0].offsetTop - 80) <=
               window.top.scrollY
            ) {
               this.curSubj = i;
            }
         }
      },
      asideActivate() {
         this.aside = !this.aside;
      },
      GoLinkRef(value) {
         this.$router.push({ name: "Escort", query: { redirect: value } });
      },
      GoToRef(index) {
         this.aside = false

         this.curSubj = index;
         if (!this.$refs[`listinstref${index}`][0]) {
            return;
         }
         this.$refs[`listinstref${index}`][0].scrollIntoView({
            behavior: "smooth",
         });
      },
      async GetAllInstruction() {
         await axios
            .get("/api/v1/get/instruction/blocks/1/")
            .then((response) => {
               this.instructions = response.data;
               console.log(response.data);
            })
            .catch((error) => {
               console.log(error);
            });
      },
   },
   components: {
      "profile-head": ProfileHeader,
   },
   watch: {},
};
</script>
