<template>
   <div class="changeMail modal authorization authorization__modal" :class="{ 'open': modal }"
      @mousedown="onMouseDownHandler" @mouseup="onMouseUpHandler">
      <div class="forget1__content modal__content authorization__content" @click.stop="">
         <div class="forget1__row authorization__row">
            <div class="forget1__title authorization__title" v-if="!edit">
               Добавить карту
            </div>
            <div class="forget1__title authorization__title" v-else>
               Изменить данные карты
            </div>
            <a @click="$emit('close', false)" class="forget1__close modal__close authorization__close"></a>
         </div>
         <form @submit.prevent="SendEscort()" class="card__info">
            <div class="card-mask__form">
               <!--                 <div class="card__inpt">
                    <span>Название банка</span>
                    <input v-model="name_bank" type="text">
                </div> -->
               <div class="bottom__flex">
                  <div class="card__inpt">
                     <span>Номер карты</span>
                     <input v-model="number_card" type="tel" v-mask="'#### #### #### ####'">
                  </div>
                  <div class="flex__cards_mask">
                     <div class="card__inpt">
                        <span>Срок действия</span>
                        <input v-model="mth_card" type="tel" v-mask="'##/##'">
                     </div>
                     <div class="card__inpt">
                        <span>CVV / CVC</span>
                        <input v-model="cvc_card" type="tel" v-mask="'###'">
                     </div>
                  </div>

               </div>
            </div>
            <div class="forget1__buttons" v-if="!edit">
               <a @click="SaveCard()" v-if="
                  number_card != '' &&
                  mth_card != '' &&
                  cvc_card != ''" class="sign__btn btn deflt active modal__link">
                  Привязать карту</a>
               <a class="sign__btn btn deflt modal__link pol-opacity_btn" v-else>Привязать карту</a>
            </div>
            <div class="forget1__buttons" v-else>
               <a @click="UpdateCard()" v-if="
                  number_card != '' &&
                  mth_card != '' &&
                  cvc_card != ''" class="sign__btn btn deflt active modal__link">
                  Обновить данные</a>
               <a class="sign__btn btn deflt modal__link pol-opacity_btn" v-else>Обновить данные</a>
            </div>
         </form>
      </div>

   </div>

</template>

<style scoped>
/*.loader__pass h2 {
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    margin: 35px 0 15px;
    color: #1B1B1E;
}*/
.flex__cards_mask {
   display: flex;
   justify-content: space-between;
}

.deflt.active {
   background: #FFD954 !important;
}

.card-mask__form {
   width: 410px;
   padding: 16px;
   border-radius: 20px;
   background: #FFD954;
   margin: 35px 0px 45px;
}

.card__inpt {
   display: flex;
   position: relative;
   flex-direction: column;
   margin: 0 0 10px;
}

.card__inpt input {
   height: 48px;
   padding: 12px;
   border-radius: 8px;
   background: #FFF;
   color: #4e4e4e;
}

.card__inpt span {
   /*    position: absolute;*/
   /*    left: 10px;*/
   /*    top: 50%;
    transform: translateY(-100%);*/
   color: #72757B;
   font-size: 13px;
   font-weight: 500;
   line-height: 20px;
   display: block;
   margin-bottom: 5px;
}

.marg__card {
   margin: 0 0 50px;
}

.marg__card input {
   width: 220px;
}

.deflt {
   border-radius: 8px;
   background: #2D2F33;
}
</style>

<script>
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { toast } from 'bulma-toast'
import moment from 'moment';

export default {
   name: "Card",
   directives: { mask },
   props: {
      modal: {
         type: Boolean,
         default: false,
      },
      edit: {
         type: Boolean,
         default: false,
      },
      card: {
         type: Object,
         default: {},
      },
   },
   components: {

   },
   data() {
      return {
         name_bank: "",
         number_card: "",
         cvc_card: "",
         mth_card: "",
         status: false,
         mouseTarget: null,
      }
   },
   beforeCreate() {
      this.$store.commit('initializeStore')
   },
   mounted() {

      console.log(this.card)
   },
   _methods: {
      onMouseDownHandler(e) {
         this.mouseTarget = e.target;
      },
      onMouseUpHandler(e) {
         if (e.target == this.mouseTarget &&
            e.target.classList.contains("modal")) {
            this.$emit("close", false);
         } else {
            this.mouseTarget = null;
         }
      },
      async SaveCard() {
         const DataNew = {
            // user: this.user,
            number_card: this.number_card,
            cvc_card: this.cvc_card,
            mth_card: this.mth_card,
         };
         // await this.sendTestPayment()
         await axios
            .post('/api/v1/multi/cards/', DataNew)
            .then(response => {
               toast({
                  message: 'Карта успешно сохранена',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
               })
               this.number_card = ""
               this.cvc_card = ""
               this.mth_card = ""
               this.$emit('close', false)
               this.$emit('status', true)
               console.log(response.data)
            })
            .catch(error => {
               console.log(error)
            })
      },
      async UpdateCard() {
         const DataNew = {
            // user: this.user,
            number_card: this.number_card,
            cvc_card: this.cvc_card,
            mth_card: this.mth_card,
         };
         await axios
            .put(`/api/v1/edit/card/${this.card.id}/`, DataNew)
            .then(response => {
               toast({
                  message: 'Карта успешно обновлена',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
               });

               this.number_card = "";
               this.cvc_card = "";
               this.mth_card = "";

               this.$emit('close', false);
               this.$emit('status', true);
               console.log(response.data);
            })
            .catch(error => {
               console.log(error);
            });
      },
   },
   get methods() {
      return this._methods;
   },
   set methods(value) {
      this._methods = value;
   },
   created() {

   },
   watch: {
      "card"(value) {
         if (Object.keys(value).length > 0) {
            this.number_card = this.card.number_card
            this.mth_card = this.card.mth_card
            this.cvc_card = this.card.cvc_card
         } else {
            this.number_card = ""
            this.cvc_card = ""
            this.mth_card = ""
         }
         console.log(value)
      },
   },
}
</script>