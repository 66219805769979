<template>
   <profile-head></profile-head>
   <main class="main">
      <section class="actions">
         <div class="container">
            <div class="actions__grid">
               <div class="search card">
                  <div class="search-header">
                     <div class="search__title title">Поиск закупок</div>
                     <!-- {{ GET_LAST_PHRASES() }} -->
                     <div class="search-header__btns">
                        <!-- <div class="search-hidden__item big mobile">
                           <span class="search__text">Поиск mobile</span>
                           <input v-model="kwords"
                              placeholder="Введите название закупки через пробел. Например: компьютер программное обеспечение ПО"
                              type="text" class="search__input" />
                        </div> -->
                        <searchSelect class="mobile" v-model="kwords" :history="GET_LAST_PHRASES()" />
                        <a @click="fmobOpen()" class="search-header__filters search__text modal__link">
                           Фильтры
                        </a>
                        <a @click="fmobtemplates()" class="search-header__templates search__text modal__link">
                           Шаблоны
                        </a>
                     </div>
                  </div>
                  <div class="search-info" v-if="templatePage">
                     <div class="search-info__row">
                        <div class="search-info__title">Шаблон</div>
                        <div class="search-info__btn search__switch">
                           <input type="checkbox" v-model="templateItem.email_rassilka" id="searchInfoEmail" />
                           <label for="searchInfoEmail"></label>
                           <span class="search__text">Включить E-mail рассылку</span>
                        </div>
                     </div>
                     <div class="search-info__input">
                        <textarea v-model="templateItem.name" class="search__input"
                           placeholder="Тендеры на этапах «Приём заявок», «Работа комиссии», «Завершён», «Отменён»  с датой окончания по 16.04.2023, с начальной ценой от Р 30 000 000 до Р 200 000 000"></textarea>
                     </div>
                     <div class="search-info__actions">
                        <div class="search-info__show">
                           <a @click="filtersView()" class="search__text">Показать параметры</a>
                           <button @click="filtersView()" :class="{ active: filters }"></button>
                        </div>
                        <div class="search-info__save" @click="PutTemplateItem(curTemplate)">
                           <span class="search__text">Сохранить изменения</span>
                           <!-- <button></button> -->
                        </div>
                        <div class="search-info__delete">
                           <span @click="GetDeleteItem(curTemplate, false)" class="search__text">Удалить шаблон</span>
                        </div>
                        <a @click="templateAdd(true)" class="search-header__templates search__text modal__link">
                           Шаблоны
                        </a>
                     </div>
                  </div>
                  <div class="search-filters" :class="{ show: filters }">
                     <div class="search-main">
                        <div class="search-stages">
                           <span class="search-stages__title search__text">Стадия закупки:</span>
                           <ul class="search-stages__list">
                              <li class="checkbox__item">
                                 <input type="checkbox" v-model="actual" :true-value="1" :false-value="0" name="stage1"
                                    id="stage1" />
                                 <label for="stage1"></label>
                                 <span for="stage1" class="search__text">Текущие</span>
                              </li>
                              <li class="checkbox__item">
                                 <input type="checkbox" name="stage3" v-model="not_actual" :true-value="1"
                                    :false-value="0" id="stage3" />
                                 <label for="stage3"></label>
                                 <span class="search__text">Завершенные</span>
                              </li>
                              <li class="checkbox__item">
                                 <input type="checkbox" name="stage31" v-model="cancel" :true-value="1" :false-value="0"
                                    id="stage31" />
                                 <label for="stage31"></label>
                                 <span class="search__text">Отмененные</span>
                              </li>
                              <!-- <li class="checkbox__item">
                                 <input type="checkbox" name="stage31" v-model="plan" :true-value="1" :false-value="0"
                                    id="stage31" />
                                 <label for="stage31"></label>
                                 <span class="search__text">Планируемые</span>
                              </li> -->
                           </ul>
                        </div>
                        <div class="search-main__grid">
                           <!-- <div class="search-hidden__item big">
                              <span class="search__text">Поиск pc</span>
                              <input v-model="kwords"
                                 placeholder="Введите название закупки через пробел. Например: компьютер программное обеспечение ПО"
                                 type="text" class="search__input" />
                           </div> -->
                           <searchSelect v-model="kwords" :history="GET_LAST_PHRASES()" />
                           <div class="search-main__item select__main">
                              <span class="search__text">Регион поставки</span>
                              <!-- <span class="search__text">Регион</span>
                              <select
                                 v-model="region"
                                 class="search__input"
                                 type="text"
                                 placeholder="Выберите регион"
                                 :class="{
                                    'not_active-select': region == 0,
                                    'active-select_color': region > 0,
                                 }"
                              >
                                 <option class="first" value="0">
                                    Выберите регион
                                 </option>
                                 <option
                                    v-for="list in regions"
                                    :value="list.ID"
                                    :key="list"
                                 >
                                    {{ list.RegionName }}
                                 </option>
                              </select> -->
                              <regionSelect :options="regions" v-model="region" placeholder="Выберите регион" />
                           </div>
                           <div class="search-main__item select__main">
                              <span class="search__text">Отрасль</span>
                              <!-- <select
                                 v-model="cat"
                                 class="search__input"
                                 type="text"
                                 placeholder="Выберите отрасль"
                                 :class="{
                                    'not_active-select': cat == 0,
                                    'active-select_color': cat > 0,
                                 }"
                              >
                                 <option class="first" value="0">
                                    Выберите отрасль
                                 </option>
                                 <option
                                    v-for="list in categoriesTenders"
                                    :value="list.ID"
                                    :key="list"
                                 >
                                    {{ list.CatName }}
                                 </option>
                              </select> -->
                              <otraslSelect :options="categoriesTenders" v-model="cat" placeholder="Выберите отрасль" />
                           </div>
                           <div class="search-main__item search-price">
                              <span class="search__text">Начальная цена, ₽</span>
                              <div class="search-price__inputs search__inputs_double">
                                 <input class="search__input" v-model="price1" type="number" id="searchPriceFrom"
                                    placeholder="от" />
                                 <input class="search__input" v-model="price2" type="number" id="searchPriceTo"
                                    placeholder="до" />
                              </div>
                           </div>
                           <div class="search-main__item hide">
                              <div class="checkbox__item">
                                 <input type="checkbox" v-model="ozprice" :true-value="1" :false-value="0" id="hide" />
                                 <label for="hide"></label>
                                 <span class="search__text">Скрывать тендеры без начальной цены</span>
                              </div>
                           </div>
                        </div>
                        <div class="search-main__button search__switch">
                           <input type="checkbox" name="searchShowMore" v-model="searchShowMore" id="searchShowMore" />
                           <label for="searchShowMore"></label>
                           <span class="search__text">Расширенный поиск</span>
                        </div>
                     </div>
                     <div class="search-hidden" :class="{ visible: searchShowMore }">
                        <div class="search-hidden__grid">
                           <div class="search-hidden__item">
                              <span class="search__text">Ключевые слова</span>
                              <input type="text" v-model="okpd2" class="search__input" placeholder="ОКПД2" />
                           </div>
                           <div class="search-hidden__item">
                              <span class="search__text">Исключения</span>
                              <input type="text" v-model="kwords_minus" class="search__input"
                                 placeholder="Например, «ремонт»" />
                           </div>

                           <div class="search-hidden__item select__main">
                              <span class="search__text">Площадки</span>
                              <!-- <select
                                 v-model="etc"
                                 class="search__input"
                                 type="text"
                                 :class="{
                                    'not_active-select': etc == 0,
                                    'active-select_color': etc > 0,
                                 }"
                              >
                                 <option class="first" value="0">
                                    Выберите площадку
                                 </option>
                                 <option
                                    v-for="list in etcTenders"
                                    :value="list.ID"
                                    :key="list"
                                 >
                                    {{ list.EtpName }}
                                 </option>
                              </select> -->
                              <etcSelect :options="etcTenders" v-model="etc" placeholder="  Выберите площадку" />
                           </div>
                           <div class="search-hidden__item">
                              <span class="search__text">Закупки по ФЗ</span>
                              <ul class="search-zakups__list">
                                 <li v-for="item in fzTypes" :key="item.value">
                                    <input type="radio" name="fzTypes" :value="item.value"
                                       :id="'fzZakupki' + item.value" v-model="fzTypeChecked">
                                    <label :for="'fzZakupki' + item.value">
                                       <i></i>
                                       <span>{{ item.name }}</span>
                                    </label>
                                 </li>
                              </ul>
                           </div>
                           <div class="search-hidden__item big">
                              <span class="search__text">ОКПД2</span>
                              <input type="number" v-model="okpd" class="search__input"
                                 placeholder="Выберите классификатор продукции" />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="search-footer">
                     <div class="search-footer__actions" v-if="!templatePage">
                        <div class="search-footer__buttons">
                           <a @click="GetSearch(page, true)" class="btn search-footer__btn yellow">Найти закупки</a>
                           <a @click="templateAdd(true)" class="btn search-footer__btn grey">Сохранить как шаблон</a>
                        </div>
                        <div @click="clearSearch()" class="search-footer__clear search__text">
                           Очистить параметры поиска
                        </div>
                     </div>
                     <div class="search-footer__actions" v-else>
                        <div class="search-footer__buttons">
                           <a @click="GetSearch(page, true)" ref="btnSearch" class="btn search-footer__btn yellow">Найти
                              закупки</a>
                           <a @click="PutTemplateItem(curTemplate)" class="btn search-footer__btn grey">Сохранить
                              изменения</a>
                           <a @click="GetDeleteItem(curTemplate, false)" class="btn search-footer__btn grey">Удалить
                              шаблон</a>
                        </div>
                        <div @click="clearSearch()" class="search-footer__clear search__text">
                           Очистить параметры поиска
                        </div>
                     </div>
                     <!--                      <div class="search-footer__desc" v-if="!alertMessage && Object.keys(user).length != 0">
                        Ищем тендеры на этапах «Приём заявок», «Работа комиссии», «Завершён», «Отменён»
                        . Дата объявления тендера по 30.03.2023, с начальной ценой контракта от Р 30 000 000 (тендеры
                        без цены
                        не показывать).
                     </div> -->
                  </div>
               </div>
               <div class="templates card">
                  <div class="templates__content">
                     <div class="templates__title">Шаблоны</div>
                     <a @click="templateAdd(true)" class="modal__link templates__create btn">
                        <span>Создать шаблон</span>
                     </a>
                     <ul class="templates__list">
                        <li class="template" v-for="list in templates" :class="{ active: list.id == curTemplate }"
                           :key="list">
                           <span @click="ViewTemplate(list.id)" class="template__title search__text not_full-text">{{
                              list.name }}</span>
                           <button class="template__btn" @click="
                              templateAction == 0
                                 ? SetActionTemplate(list.id)
                                 : removeActionTemplate()
                              "></button>
                           <div class="outside" v-if="templateAction == list.id" @click="removeActionTemplate()"></div>
                           <div class="template-actions" :class="{ show: templateAction == list.id }">
                              <ul class="template-actions__list">
                                 <li class="template-actions__item" @click="ViewTemplate(list.id)">
                                    <button>
                                       <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <path
                                             d="M18.7103 9.4137L15.0051 5.73151L16.2256 4.50411C16.5598 4.16804 16.9704 4 17.4575 4C17.9439 4 18.3543 4.16804 18.6885 4.50411L19.909 5.73151C20.2432 6.06758 20.4175 6.47321 20.4321 6.94838C20.4466 7.42298 20.2868 7.82831 19.9526 8.16438L18.7103 9.4137ZM17.4462 10.7068L8.20513 20H4.5V16.274L13.741 6.98082L17.4462 10.7068Z"
                                             fill="white" />
                                          <path fill-rule="evenodd" clip-rule="evenodd"
                                             d="M21.5 20H12.3462V18.2466H21.5V20Z" fill="white" />
                                       </svg>
                                       <span>Редактировать</span>
                                    </button>
                                 </li>
                                 <li @click="GetDeleteItem(list.id, true)" class="template-actions__item">
                                    <button>
                                       <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <path
                                             d="M4.99935 15.8333C4.99935 16.75 5.74935 17.5 6.66602 17.5H13.3327C14.2493 17.5 14.9993 16.75 14.9993 15.8333V7.5C14.9993 6.58333 14.2493 5.83333 13.3327 5.83333H6.66602C5.74935 5.83333 4.99935 6.58333 4.99935 7.5V15.8333ZM14.9993 3.33333H12.916L12.3243 2.74167C12.1743 2.59167 11.9577 2.5 11.741 2.5H8.25768C8.04102 2.5 7.82435 2.59167 7.67435 2.74167L7.08268 3.33333H4.99935C4.54102 3.33333 4.16602 3.70833 4.16602 4.16667C4.16602 4.625 4.54102 5 4.99935 5H14.9993C15.4577 5 15.8327 4.625 15.8327 4.16667C15.8327 3.70833 15.4577 3.33333 14.9993 3.33333Z"
                                             fill="#1E2024" />
                                       </svg>
                                       <span>Удалить</span>
                                    </button>
                                 </li>
                              </ul>
                              <div class="template-actions__email search__switch">
                                 <input type="checkbox" :checked="list.email_rassilka" :value="list.email_rassilka"
                                    :id="list.id" :name="list.id" @click="
                                       SaveRassilka(
                                          list.email_rassilka,
                                          list.name,
                                          list.id
                                       )
                                       " />
                                 <label @click="
                                    SaveRassilka(
                                       list.email_rassilka,
                                       list.name,
                                       list.id
                                    )
                                    " :id="list.id">
                                 </label>
                                 <span>Включить E-mail рассылку</span>
                              </div>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="templates card modal" :class="{ open: ftemplate }" id="templates">
                  <div class="templates__content modal__content">
                     <div class="templates__header">
                        <div class="templates__title">Шаблоны</div>
                        <a class="templates__close modal__close" @click="ftemplate = false">
                           <img src="@/assets/img/icons/notifications__close.svg" alt="" />
                        </a>
                     </div>
                     <a @click="templateAdd(true)" class="templates__create btn modal__link">
                        <span>Создать шаблон</span>
                     </a>
                     <ul class="templates__list" v-if="ftemplate">
                        <li class="template" v-for="list in templates" :class="{ active: list.id == curTemplate }"
                           :key="list">
                           <span @click="ViewTemplate(list.id)" class="template__title search__text not_full-text">{{
                              list.name }}</span>
                           <button class="template__btn" @click="
                              templateAction == 0
                                 ? SetActionTemplate(list.id)
                                 : removeActionTemplate()
                              "></button>
                           <div class="outside" v-if="templateAction == list.id" @click="removeActionTemplate()"></div>
                           <div class="template-actions" :class="{ show: templateAction == list.id }">
                              <ul class="template-actions__list">
                                 <li class="template-actions__item" @click="ViewTemplate(list.id)">
                                    <button>
                                       <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <path
                                             d="M18.7103 9.4137L15.0051 5.73151L16.2256 4.50411C16.5598 4.16804 16.9704 4 17.4575 4C17.9439 4 18.3543 4.16804 18.6885 4.50411L19.909 5.73151C20.2432 6.06758 20.4175 6.47321 20.4321 6.94838C20.4466 7.42298 20.2868 7.82831 19.9526 8.16438L18.7103 9.4137ZM17.4462 10.7068L8.20513 20H4.5V16.274L13.741 6.98082L17.4462 10.7068Z"
                                             fill="white" />
                                          <path fill-rule="evenodd" clip-rule="evenodd"
                                             d="M21.5 20H12.3462V18.2466H21.5V20Z" fill="white" />
                                       </svg>
                                       <span>Редактировать</span>
                                    </button>
                                 </li>
                                 <li @click="GetDeleteItem(list.id, true)" class="template-actions__item">
                                    <button>
                                       <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <path
                                             d="M4.99935 15.8333C4.99935 16.75 5.74935 17.5 6.66602 17.5H13.3327C14.2493 17.5 14.9993 16.75 14.9993 15.8333V7.5C14.9993 6.58333 14.2493 5.83333 13.3327 5.83333H6.66602C5.74935 5.83333 4.99935 6.58333 4.99935 7.5V15.8333ZM14.9993 3.33333H12.916L12.3243 2.74167C12.1743 2.59167 11.9577 2.5 11.741 2.5H8.25768C8.04102 2.5 7.82435 2.59167 7.67435 2.74167L7.08268 3.33333H4.99935C4.54102 3.33333 4.16602 3.70833 4.16602 4.16667C4.16602 4.625 4.54102 5 4.99935 5H14.9993C15.4577 5 15.8327 4.625 15.8327 4.16667C15.8327 3.70833 15.4577 3.33333 14.9993 3.33333Z"
                                             fill="#1E2024" />
                                       </svg>
                                       <span>Удалить</span>
                                    </button>
                                 </li>
                              </ul>
                              <div class="template-actions__email search__switch">
                                 <input type="checkbox" :checked="list.email_rassilka" :value="list.email_rassilka"
                                    :id="list.id" :name="list.id" @click="
                                       SaveRassilka(
                                          list.email_rassilka,
                                          list.name,
                                          list.id
                                       )
                                       " />
                                 <label @click="
                                    SaveRassilka(
                                       list.email_rassilka,
                                       list.name,
                                       list.id
                                    )
                                    " :id="list.id">
                                 </label>
                                 <span>Включить E-mail рассылку</span>
                              </div>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="search-filters modal" :class="{ open: fmob_open }" id="filters">
                  <div class="search-filters__content modal__content">
                     <div class="search-filters__wrapper">
                        <div class="search-filters__header">
                           <div class="search-filters__title">Фильтры</div>
                           <a class="search-filters__close modal__close" @click="fmob_open = false">
                              <img src="@/assets/img/icons/notifications__close.svg" alt="" />
                           </a>
                        </div>
                        <div class="search-main">
                           <div class="search-hidden__item big pc">
                              <span class="search__text">Поиск</span>
                              <input v-model="kwords"
                                 placeholder="Введите название закупки через пробел. Например: компьютер программное обеспечение ПО"
                                 type="text" class="search__input" />
                           </div>
                           <div class="search-stages">
                              <span class="search-stages__title search__text">Стадия закупки:</span>
                              <ul class="search-stages__list">
                                 <li class="checkbox__item">
                                    <input type="checkbox" v-model="actual" :true-value="1" :false-value="0"
                                       name="stage1" id="stage1" />
                                    <label for="stage1"></label>
                                    <span for="stage1" class="search__text">Текущие</span>
                                 </li>
                                 <li class="checkbox__item">
                                    <input type="checkbox" name="stage3" v-model="not_actual" :true-value="1"
                                       :false-value="0" id="stage3" />
                                    <label for="stage3"></label>
                                    <span class="search__text">Завершенные</span>
                                 </li>
                                 <li class="checkbox__item">
                                    <input type="checkbox" name="stage30" v-model="cancel" :true-value="1"
                                       :false-value="0" id="stage30" />
                                    <label for="stage30"></label>
                                    <span class="search__text">Отмененные</span>
                                 </li>
                              </ul>
                           </div>
                           <div class="search-main__grid">
                              <div class="search-main__item select__main">
                                 <span class="search__text">Регион поставки</span>
                                 <!-- <select
                                    v-model="region"
                                    class="search__input"
                                    type="text"
                                    placeholder="Выберите регион"
                                    :class="{
                                       'not_active-select': region == 0,
                                       'active-select_color': region > 0,
                                    }"
                                 >
                                    <option class="first" value="0">
                                       Выберите регион
                                    </option>
                                    <option
                                       v-for="list in regions"
                                       :value="list.ID"
                                       :key="list"
                                    >
                                       {{ list.RegionName }}
                                    </option>
                                 </select> -->
                                 <regionSelect :options="regions" v-model="region" placeholder="Выберите регион" />
                              </div>
                              <div class="search-main__item select__main">
                                 <span class="search__text">Отрасль</span>
                                 <!-- <select
                                    v-model="cat"
                                    class="search__input"
                                    type="text"
                                    placeholder="Выберите отрасль"
                                    :class="{
                                       'not_active-select': cat == 0,
                                       'active-select_color': cat > 0,
                                    }"
                                 >
                                    <option class="first" value="0">
                                       Выберите отрасль
                                    </option>
                                    <option
                                       v-for="list in categoriesTenders"
                                       :value="list.ID"
                                       :key="list"
                                    >
                                       {{ list.CatName }}
                                    </option>
                                 </select> -->
                                 <otraslSelect :options="categoriesTenders" v-model="cat"
                                    placeholder="Выберите отрасль" />
                              </div>
                              <div class="search-main__item search-price">
                                 <span class="search__text">Начальная цена, ₽</span>
                                 <div class="search-price__inputs search__inputs_double">
                                    <input class="search__input" v-model="price1" type="number" id="searchPriceFrom"
                                       placeholder="от" />
                                    <input class="search__input" v-model="price2" type="number" id="searchPriceTo"
                                       placeholder="до" />
                                 </div>
                              </div>
                              <div class="search-main__item hide">
                                 <div class="checkbox__item">
                                    <input type="checkbox" v-model="ozprice" :true-value="1" :false-value="0"
                                       id="hide" />
                                    <label for="hide"></label>
                                    <span class="search__text">Скрывать тендеры без начальной
                                       цены</span>
                                 </div>
                              </div>
                           </div>
                           <div class="search-main__button">
                              <input type="checkbox" name="" v-model="searchShowMore" id="searchShowMoreAdapt" />
                              <label for="searchShowMoreAdapt" class="search__text">Расширенные фильтры</label>
                              <!-- <span class="search__text">Расширенные фильтры</span> -->
                           </div>
                        </div>
                        <div class="search-hidden search-hidden_adapt" :class="{ visible: searchShowMore }">
                           <div class="search-hidden__grid">
                              <div class="search-hidden__item">
                                 <span class="search__text">Ключевые слова</span>
                                 <input type="text" v-model="okpd2" class="search__input" placeholder="ОКПД2" />
                              </div>
                              <div class="search-hidden__item">
                                 <span class="search__text">Исключения</span>
                                 <input type="text" v-model="kwords_minus" class="search__input"
                                    placeholder="Например, «ремонт»" />
                              </div>

                              <div class="search-hidden__item big select__main">
                                 <span class="search__text">Площадки</span>
                                 <!-- <select
                                    v-model="etc"
                                    class="search__input"
                                    type="text"
                                    :class="{
                                       'not_active-select': etc == 0,
                                       'active-select_color': etc > 0,
                                    }"
                                 >
                                    <option class="first" value="0">
                                       Выберите площадку
                                    </option>
                                    <option
                                       v-for="list in etcTenders"
                                       :value="list.ID"
                                       :key="list"
                                    >
                                       {{ list.EtpName }}
                                    </option>
                                 </select> -->
                                 <etcSelect :options="etcTenders" v-model="etc" placeholder="  Выберите площадку" />
                              </div>
                              <div class="search-hidden__item">
                                 <span class="search__text">Закупки по ФЗ</span>
                                 <ul class="search-zakups__list">
                                    <li v-for="item in fzTypes" :key="item.value">
                                       <input type="radio" name="fzTypesmobile" :value="item.value"
                                          :id="'fzZakupkimobile' + item.value" v-model="fzTypeChecked">
                                       <label :for="'fzZakupkimobile' + item.value">
                                          <i></i>
                                          <span>{{ item.name }}</span>
                                       </label>
                                    </li>
                                 </ul>
                              </div>
                              <div class="search-hidden__item big">
                                 <span class="search__text">ОКПД2</span>
                                 <input type="number" v-model="okpd" class="search__input"
                                    placeholder="Выберите классификатор продукции" />
                              </div>
                           </div>
                           <div class="search-main__button">
                              <input type="checkbox" name="" id="searchShowMoreAdapt" />
                              <label for="searchShowMoreAdapt" class="search__text">Расширенные фильтры</label>
                              <!-- <span class="search__text">Расширенные фильтры</span> -->
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="search-filters__buttons_adapt">
                     <button class="search-filters__button_adapt btn white" @click="GetSearch(page)">
                        Применить фильтр
                     </button>
                     <button class="search-filters__button_adapt btn grey" @click="templateAdd(true)">
                        Сохранить как шаблон
                     </button>
                     <button class="search-filters__button_adapt clear search__text" @click="clearSearch()">
                        Очистить фильтр
                     </button>
                  </div>
               </div>
               <div class="template-modal modal" id="createTemplate" :class="{ open: template }"
                  @click="template = false">
                  <div class="modal__content template-modal__content" @click.stop="">
                     <header class="template-modal__header">
                        <div class="template-modal__title">
                           Создание шаблона
                        </div>
                        <div class="template-modal__close modal__close" @click="template = false"></div>
                     </header>
                     <main class="template-modal__main">
                        <div class="template-modal__input">
                           <div class="template-modal__subtitle search__text">
                              Название шаблона
                           </div>
                           <textarea v-model="template_name" class="template-modal__textarea search__input" id=""
                              cols="30" rows="10"></textarea>
                        </div>
                        <div class="template-modal__not checkbox__item">
                           <input v-model="email_rassilka" type="checkbox" id="templateModalNot" />
                           <label for="templateModalNot"></label>
                           <p class="search__text">
                              Отправлять уведомления о появлении новых закупок
                              по этому шаблону на электронную почту
                           </p>
                        </div>
                     </main>
                     <footer class="template-modal__footer">
                        <div @click="
                           template_name != ''
                              ? PostTemplate()
                              : (template_name = 'Заполните название')
                           " class="template-modal__save btn">
                           Сохранить
                        </div>
                        <div @click="template = false" class="template-modal__cancel modal__close btn">
                           Отмена
                        </div>
                     </footer>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="tenders" ref="tenders" v-if="user.payment">
         <div class="container">
            <div class="tenders__wrapper" :class="{ gap_none: result.length == 0 || loader }">
               <div class="tenders-main" v-if="result.length != 0">
                  <div class="tenders-main__row">
                     <div class="tenders-main__sort">
                        <span>Сортировать:</span>
                        <v-select :settings="sortSettings" @selectOption="selectSort" />
                     </div>
                     <!-- <div class="tenders-main__sort">
                        <span>Сортировать:</span>
                        <form>
                           <div class="tenders-select" data-state="">
                              <div class="tenders-select__title" data-default="Сначала новые">Сначала новые</div>
                              <div class="tenders-select__content">
                                 <input id="singleSelect0" class="tenders-select__input" type="radio"
                                    name="singleSelect" checked />
                                 <label for="singleSelect0" class="tenders-select__label">Сначала новые</label>
                                 <input id="singleSelect1" class="tenders-select__input" type="radio"
                                    name="singleSelect" />
                                 <label for="singleSelect1" class="tenders-select__label">Сначала новые</label>
                                 <input id="singleSelect2" class="tenders-select__input" type="radio"
                                    name="singleSelect" />
                                 <label for="singleSelect2" class="tenders-select__label">Сначала новые 1</label>
                                 <input id="singleSelect3" class="tenders-select__input" type="radio"
                                    name="singleSelect" />
                                 <label for="singleSelect3" class="tenders-select__label">Сначала новые 2</label>
                              </div>
                           </div>
                        </form>
                     </div> -->
                     <download-excel class="tenders-main__save tenders__text_small" :data="dataExcel"
                        :fields="json_fields" type="xlsx" worksheet="Данные" name="search.xlsx">
                        Сохранить в Excel
                     </download-excel>
                     <!-- <a class="tenders-main__save tenders__text_small">Сохранить в Excel</a> -->
                  </div>
                  <ul class="tenders-main__list">
                     <li class="tenders-main__item tender" v-for="(list, index) in result" v-show="index > 0"
                        :key="list.in_fav">
                        <header class="tender-header">
                           <div class="tender-header__info">
                              <ul class="tender-header__list" v-if="list.Etp">
                                 <li class="tek">
                                    {{ list.Etp }}
                                 </li>
                                 <li class="rus" v-if="list.Fz">
                                    {{ list.Fz }}-ФЗ
                                 </li>
                                 <!-- <li class="auk">АУК</li> -->
                              </ul>
                              <div class="tender-header__place tenders__text_small" v-if="list.Region">
                                 {{ list.Region }}
                              </div>
                           </div>
                           <div class="tender-header__name" v-if="list.Date && list.TenderNumOuter">
                              Тендер №{{ list.TenderNumOuter }} от
                              {{ list.Date }}
                           </div>
                        </header>
                        <main class="tender-main">
                           <!-- <div class="tender-main__info" @click="RedirectTo('/profile/tender/' + list.ID)">
                              <p class="tender-main__text big" v-if="list.TenderName" v-html="list.TenderName">
                              </p>
                              <p class="tender-main__text small" v-if="list.Category">
                                 {{ list.Category }}
                              </p>
                           </div> -->
                           <router-link :to="'/profile/tender/' + list.ID" class="tender-main__info">
                              <p class="tender-main__text big" v-if="list.TenderName" v-html="list.TenderName"></p>
                              <p class="tender-main__text small" v-if="list.Category">
                                 {{ list.Category }}
                              </p>
                           </router-link>
                           <div class="tender-main__prices">
                              <div class="tender-main__price" v-if="list.Price">
                                 {{ numberWithSpaces(list.Price) }} ₽
                              </div>
                           </div>
                        </main>
                        <footer class="tender-footer">
                           <div class="tender-footer__price" v-if="list.Price">
                              {{ numberWithSpaces(list.Price) }} ₽
                           </div>
                           <div class="tender-footer__info">
                              <div class="tender-footer__time tenders__text_small" v-if="list.EndTime">
                                 <span class="light">Завершение приема заявок (МСК)
                                    {{ list.EndTime }}
                                 </span>
                                 <!-- <span class="dark">10:00</span> -->
                              </div>
                              <div class="tender-footer__remind tenders__text_small" v-show="list.EndTime" :class="{
                                 'middle-timer':
                                    CheckData(list.EndTime) == 2,
                                 'success-timer':
                                    CheckData(list.EndTime) == 1,
                                 end_timer: CheckData(list.EndTime) == 0,
                              }">
                                 {{ GetData(list.EndTime) }}
                              </div>
                           </div>
                           <div class="tender-footer__actions">
                              <span style="opacity: 0">{{
                                 CheckFavorite(list.ID)
                                 }}</span>
                              <span style="opacity: 0">{{
                                 CheckBlock(list.ID)
                                 }}</span>
                              <div class="tender-footer__hide tenders__text_small" @click="BlockTender(list.ID, index)">
                                 Скрыть
                              </div>
                              <div class="tender-footer__favorites tenders__text_small" :class="{
                                 'tender-footer__favorites_clear':
                                    list.in_fav,
                              }">
                                 <span @click="UnFav(list.ID)" v-if="list.in_fav">В избранном</span>
                                 <span v-else @click="openFav(list.ID)">В избранное</span>
                                 <button v-if="!list.in_fav" @click="openFav(list.ID)" class="tender-footer__favor_btn"
                                    :class="{ active: list.ID == currentFav }"></button>
                                 <div class="outside" v-if="list.ID == currentFav" @click="openFav(list.ID)"></div>
                                 <ul class="tender-footer__sublist" :class="{ show: list.ID == currentFav }">
                                    <li v-for="item in favObjects" @click="AddFavorite(list, item.id)" :key="item">
                                       {{ item.name }}
                                    </li>
                                 </ul>
                                 <v-popup :isOpen="isFavoriteModalOpen" @closePopup="isFavoriteModalOpen = false">
                                    <div
                                       class="forget2__content modal__content authorization__content favor-modal__content">
                                       <div class="forget2__row authorization__row">
                                          <div class="forget1__title authorization__title">Создать метку</div>
                                          <a @click="isFavoriteModalOpen = false"
                                             class="promocode__close modal__close authorization__close"></a>
                                       </div>
                                       <div class="forget1__input authorization__input">
                                          <span>Название метки</span>
                                          <input v-model="favorName" type="text" />
                                       </div>
                                       <div class="favor-modal__btns">
                                          <button class="favor-modal__btn favor-modal__cancel"
                                             @click='saveNewFavor'>Сохранить</button>
                                          <button class="favor-modal__btn favor-modal__save"
                                             @click="isFavoriteModalOpen = false">Отмена</button>
                                       </div>
                                    </div>
                                 </v-popup>
                              </div>
                           </div>
                        </footer>
                     </li>
                  </ul>
                  <!--                   <button class="tenders-main__btn" id="tendersShowMore">
                     Показать еще
                  </button> -->
               </div>
               <div class="tenders-main" v-else>
                  <div class="loader" v-if="loader">
                     <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                     </div>
                  </div>
                  <div v-else>
                     <h3 class="emtpy-search_title" v-if="firstSearch">
                        Данных пока нет
                     </h3>
                     <h3 class="emtpy-search_title" v-else>
                        Введите поисковой запрос
                     </h3>
                  </div>
               </div>
               <div class="tenders-footer" ref="pagination" v-if="result.length != 0">
                  <ul class="tenders-footer__pages" v-if="page_max > 0 && page_max != 1">
                     <li v-show="current_page - 1 != 0" @click="setPage(current_page - 1)">
                        <a>
                           <p>{{ current_page - 1 }}</p>
                        </a>
                     </li>
                     <li v-for="list in filterNumbers" v-show="list != page_max" :key="list"
                        :class="{ active: current_page == list.num }" @click="setPage(list.num)">
                        <a>
                           <p>{{ list.num }}</p>
                        </a>
                     </li>
                     <li>
                        <p>...</p>
                     </li>
                     <li @click="setPage(page_max)" :class="{ active: current_page == page_max }">
                        <a>
                           <p>{{ page_max }}</p>
                        </a>
                     </li>
                  </ul>
                  <form v-if="page_max > 0 && page_max != 1" @submit.prevent="setPage(choosePage)"
                     class="tenders-footer__search">
                     <span>На страницу</span>
                     <input v-model="choosePage" type="tel" v-mask="'##'" />
                     <button :disabled="choosePage == 0 || choosePage > page_max" @click="setPage(choosePage)">
                        Перейти
                     </button>
                  </form>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>
<script>
import axios from "axios";
import { mask } from "vue-the-mask";
import { toast } from "bulma-toast";
import moment from "moment";
import JsonExcel from "vue-json-excel3";
import { mapGetters, mapMutations } from "vuex";
import ProfileHeader from "@/components/Header/ProfileHeader";
import regionSelect from "@/components/Select/region-select.vue";
import otraslSelect from "@/components/Select/otrasl-select.vue";
import searchSelect from "@/components/Select/search-select.vue";
import etcSelect from "@/components/Select/etc-select.vue";
import vSelect from '@/components/Select/v-select.vue'
import vPopup from '@/components/Modal/v-popup.vue'
export default {
   name: "Search",
   directives: { mask },
   data() {
      return {
         favorName: '',
         isFavoriteModalOpen: false,
         sortSettings: {
            options: [
               {
                  name: 'Сначала новые',
                  value: 'asc',
                  type: 'by_date',
               },
               {
                  name: 'Сначала старые',
                  value: 'desc',
                  type: 'by_date',
                  selected: true,
               },
               {
                  name: 'По убыванию цены',
                  value: 'desc',
                  type: 'by_price',
               },
               {
                  name: 'По возрастанию цены',
                  value: 'asc',
                  type: 'by_price',
               },
            ],
            placeholder: ""
         },
         selectedSort: {},
         page: 1,
         pageLimit: null,
         pageOffset: 20,
         page_max: 0,
         limit: 5,

         choosePage: 1,

         current_page: 0,

         template: false,
         templates: [],

         searchShowMore: false,

         result: [],

         currentFav: 0,
         templateAction: 0,

         user: {},

         regions: [],
         etcTenders: [],

         categoriesTenders: [],

         loader: false,

         status: false,

         favObjects: [],

         price1: "",
         price2: "",
         region: 0,
         cat: 0,
         ozprice: 0,
         obprice: 0,

         kwords: "",

         actual: false,
         not_actual: false,
         cancel: false,

         kwords_minus: "",

         etc: 0,

         okpd2: "",

         templatePage: false,
         template_name: "",
         email_rassilka: false,

         curTemplate: 0,

         templateItem: {},

         filters: false,

         fmob_open: false,
         ftemplate: false,

         alertMessage: false,
         statusBlock: false,

         json_fields: {
            Наименование: "TenderName",
            Категория: "Category",
            Заказчик: "Customer",
            Регион: "Region",
            Площадка: "Etp",
            Категория: "Category",
            "Дата окончания": "EndTime",
            ФЗ: "Fz",
            "Начальная цена": "Price",
            "Номер тендера": "TenderNumOuter",
            // City: "city",
            // Telephone: "phone.mobile",
         },
         firstSearch: false,

         okpd: '',
         fzTypes: [
            {
               name: '44 ФЗ',
               value: '44'
            },
            {
               name: '223 ФЗ',
               value: '223'
            },
            {
               name: 'Коммерческие',
               value: 'kom'
            },
         ],
         fzTypeChecked: '',
         plan: 0,
      };
   },
   async mounted() {
      await this.GetMeInfo();
      await this.GetRegions();
      await this.setFilters(this.GET_SEARCH_DATA());
   },
   methods: {
      ...mapMutations(["CHANGE_SEARCH_DATA", "SAVE_LAST_SEARCH_PHRASES"]),
      ...mapGetters(["GET_SEARCH_DATA", "GET_LAST_PHRASES"]),
      async selectSort(option) {
         this.selectedSort = option
         // this.result = []
         await this.GetSearch(this.current_page)
      },
      async saveNewFavor() {
         if (!this.favorName.length) {
            toast({
               message: "Введите название",
               type: "is-error",
               dismissible: true,
               pauseOnHover: true,
               duration: 3000,
               position: "bottom-right",
            });
            return
         }
         let { data, status } = await axios.post('/api/v1/favorite/statuses', {
            status_name: this.favorName
         })
         if (status == 201) {
            this.isFavoriteModalOpen = false
            toast({
               message: "Метка успешно создана",
               type: "is-success",
               dismissible: true,
               pauseOnHover: true,
               duration: 3000,
               position: "bottom-right",
            });
         }
         await this.getFavorFilters()
      },
      async getFavorFilters() {
         let { data } = await axios('/api/v1/favorite/statuses')
         let formatData = data.map(item => {
            return {
               id: item.status,
               name: item.status_name
            }
         })
         let start = [
            {
               id: 4,
               name: "Проанализировать",
            },
            {
               id: 3,
               name: "Участвовать",
            },
            {
               id: 2,
               name: "Выиграли",
            },
            {
               id: 1,
               name: "Субподряд",
            },
         ]
         let arr = start.concat(formatData)
         arr.push(
            {
               id: 999,
               name: 'Создать свою метку'
            }
         )
         this.favObjects = arr;
         console.log('favor', this.favObjects);
      },
      async saveHistorySubRequest() {
         await axios
            .get("api/v1/get/save/history/sub/requests/")
            .then((res) => {
               console.log(res);
            })
            .catch((err) => {
               console.log(err);
            });
      },
      async UnFav(list_id, index, not_splice = false) {
         await axios
            .delete(`/api/v1/favorite/delete/tender/${list_id}/`)
            .then((response) => {
               console.log(response);
               this.result.forEach((item) => {
                  if (item.ID == list_id) {
                     item.in_fav = false;
                  }
               });
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetMeInfo() {
         await axios
            .get("/api/v1/me/")
            .then((response) => {
               this.user = response.data;

               if (!response.data.alert) {
                  setTimeout(() => {
                     this.GetAlert();
                  }, 10000);
               } else {
                  this.alertMessage = true;
               }
               // console.log(response.data)
            })
            .catch((error) => {
               console.log(error);
            });
      },
      clearSearch() {
         this.kwords = "";
         this.ozprice = 0;
         this.cat = 0;
         this.region = 0;
         this.price1 = "";
         this.price2 = "";
         this.actual = false;
         this.not_actual = false;
         this.cancel = false;
         this.kwords_minus = "";
         this.etc = 0;
         this.okpd2 = "";
      },
      GoToRef(val) {
         this.$refs[val].scrollIntoView({ behavior: "smooth" });
      },
      RedirectTo(value) {
         this.$router.push(value);
      },
      openFav(value) {
         if (this.currentFav != value) {
            this.currentFav = value;
         } else {
            this.currentFav = 0;
         }
      },
      templateAdd(bool) {
         this.template = bool;
      },
      setPage(value) {
         this.current_page = value;
         this.$router.replace({
            name: "Search",
            query: { page: Number(value) },
         });
         this.GetSearch(value);
      },
      numberWithSpaces(x) {
         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      async BlockTender(id, index) {
         const dataForm = {
            get_tenderID: id,
            user: this.user.user,
         };

         await axios
            .post("/api/v1/block/post/", dataForm)
            .then((response) => {
               this.result.splice(index, 1);

               // console.log(response.data)
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async AddFavorite(list, value) {
         if (value == 999) {
            this.isFavoriteModalOpen = true
            this.currentFav = 0
            return
         }
         const dataForm = {
            get_tenderID: list.ID,
            user: this.user.user,
            tender: list,
            status: value,
         };

         await axios
            .post("/api/v1/favorite/post/", dataForm)
            .then((response) => {
               this.status = true;
               this.currentFav = 0;

               var inFav = this.result.findIndex((item) => item.ID === list.ID);
               // console.log(response.data, inFav)
               this.result[inFav].in_fav = true;

               // console.log(this.result)
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetAlert() {
         await axios
            .get(`/api/v1/get/alert/`)
            .then((response) => {
               this.alertMessage = response.data.alert;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetRegions() {
         await axios
            .get(`/api/v1/get/regions/`)
            .then((response) => {
               this.regions = response.data;
               this.regions.sort((a, b) =>
                  a.RegionName > b.RegionName ? 1 : -1
               );
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetEtcTenders() {
         await axios
            .get(`/api/v1/get/etc/tenders/`)
            .then((response) => {
               this.etcTenders = response.data;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetCategoriesTenders() {
         await axios
            .get(`/api/v1/get/tenders/categories/`)
            .then((response) => {
               console.log("cat", response.data);
               this.categoriesTenders = response.data;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetTemplates() {
         await axios
            .get(`/api/v1/get/post/templates/`)
            .then((response) => {
               this.templates = response.data;
               // console.log(response.data)
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetTemplateItem(value) {
         await axios
            .get(`/api/v1/template/item/${value}/`)
            .then((response) => {
               this.templateItem = response.data;
               // console.log(response.data)

               this.setFilters(response.data.template);
               this.result = [];
               this.loader = true;
               this.page = 1;
               this.pageLimit = null;
               this.pageOffset = 20;
               this.page_max = 0;
               this.limit = 5;
               this.$refs.btnSearch.click();
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetDeleteItem(value, menu) {
         await axios
            .delete(`/api/v1/delete/template/${value}/`)
            .then((response) => {
               // console.log(response.data)
               this.GetTemplates();

               if (!menu)
                  this.$router.push({ name: "Search" }), this.clearSearch();
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async PutTemplateItem(value) {
         var object = {};

         object.email_rassilka = this.templateItem.email_rassilka;
         object.name = this.templateItem.name;
         object.template = this.ObjectData();
         object.user = this.user.user;

         await axios
            .put(`/api/v1/template/item/${value}/`, object)
            .then((response) => {
               this.templateItem = response.data;

               toast({
                  message: "Данные обновлены.",
                  type: "is-success",
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: "bottom-right",
               });

               this.GetTemplates();
               this.setFilters(response.data.template);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async PostTemplate() {
         var object = {};

         object.email_rassilka = this.email_rassilka;
         object.name = this.template_name;
         object.template = this.ObjectData();
         object.user = this.user.user;

         await axios
            .post(`/api/v1/get/post/templates/`, object)
            .then((response) => {
               this.template = false;
               this.GetTemplates();
               // console.log(response.data)
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetSearch(page, isBtn) {
         console.log("поиск по странице", page);
         // var object = this.ObjectData()
         this.fmob_open = false;
         this.loader = true;
         this.firstSearch = true;
         if (this.user.payment == false) {
            toast({
               message: "Тариф не оплачен или недействителен.",
               type: "is-success",
               dismissible: true,
               pauseOnHover: true,
               duration: 3000,
               position: "bottom-right",
            });
            return;
         }
         console.log('this.ObjectData', this.ObjectData());
         this.SAVE_LAST_SEARCH_PHRASES(this.kwords)
         await axios
            .post(`/api/v1/search/tenders/${Number(page)}/`, this.ObjectData())
            .then((response) => {
               this.saveHistorySubRequest();
               console.log('response', response.data);
               this.result = response.data;
               this.loader = false;

               if (response.data[0].Total) {
                  this.pageLimit = Number(response.data[0].Total);
                  // this.page_max = Math.round(this.pageLimit / this.pageOffset)
                  this.page_max = Math.ceil(this.pageLimit / this.pageOffset);
                  if (isBtn) {
                     let topres = this.$refs.tenders.offsetTop;
                     console.log(topres);
                     window.scrollTo({
                        top: topres - 80,
                        behavior: "smooth",
                     });
                  }
                  if (this.page_max > 50) {
                     this.page_max = 50;
                  }
               }
               this.dataExcel = response.data;
               this.dataExcel.splice(0, 1);
               console.log("excel", this.dataExcel);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async CheckFavorite(value) {
         await axios
            .get(`/api/v1/favorite/check/${value}/`)
            .then((response) => {
               this.status = response.data.status;

               if (this.status == "success") {
                  var inFav = this.result.findIndex(
                     (item) => item.ID === value
                  );
                  this.result[inFav].in_fav = true;
               }
            })
            .catch((error) => {
               // console.log(error)
            });
      },
      async CheckBlock(value) {
         await axios
            .get(`/api/v1/block/check/${value}/`)
            .then((response) => {
               this.statusBlock = response.data.status;
               // console.log(response.data)

               if (response.data.status == "success") {
                  var indBlock = this.result.findIndex(
                     (item) => item.ID === value
                  );
                  // console.log(indBlock)
                  this.result[indBlock].in_block = true;
                  this.result.splice(indBlock, 1);
               }
            })
            .catch((error) => {
               // console.log(error)
            });
      },
      ViewTemplate(value) {
         this.ftemplate = false;
         this.$router.push({
            name: "Search",
            query: { redirect: "templatePage", uid: value },
         });
      },
      SetActionTemplate(value) {
         this.templateAction = value;
         // console.log(this.templateAction)
      },
      removeActionTemplate() {
         this.templateAction = 0;
      },
      filtersView() {
         this.filters = !this.filters;
      },
      fmobOpen() {
         this.fmob_open = !this.fmob_open;
      },
      fmobtemplates() {
         this.ftemplate = !this.ftemplate;
      },
      SaveExcel() {
         print();
      },
      ObjectData() {
         var object = {};
         if (this.selectedSort?.type) {
            object.sort_by = this.selectedSort.type
            object.sort = this.selectedSort.value

         }
         if (this.actual == 1) object.actual = 1;
         if (this.plan == 1) object.plan = 1;
         if (this.kwords) {
            object.kwords = this.kwords
         };
         if (this.ozprice == 1) object.ozprice = 1;
         if (this.cat != 0) object.cat = this.cat;
         if (this.region != 0) object.region = this.region;
         if (this.price1) object.price1 = Number(this.price1);
         if (this.price2) object.price2 = Number(this.price2);
         if (this.not_actual) object.not_actual = this.not_actual;
         if (this.cancel) object.cancel = this.cancel;
         if (this.kwords_minus) object.kwords_minus = this.kwords_minus;
         if (this.etc != 0) object.etc = this.etc;
         if (this.okpd2 && this.okpd2 != '') object.okpd2 = this.okpd2;
         if (this.okpd) object.okpd = this.okpd.toString();
         if (this.fzTypeChecked.length) object.f = this.fzTypeChecked
         console.log('object', object);
         return object;
      },

      async setFilters(value) {
         // console.log('filters', value);
         var object = value;

         if (value.actual == 1) this.actual = 1;
         if (value.kwords) {
            this.kwords = value.kwords
         };
         if (value.ozprice == 1) this.ozprice = 1;
         if (value.cat && value.cat != 0) {
            let checkedCat = this.categoriesTenders.find(item => item.ID == value.cat)
            if (checkedCat?.CatName) {
               setTimeout(() => {
                  document.querySelector('#catSelect input').value = checkedCat.CatName
               })
            }
            this.cat = value.cat
         }
         if (value.region && value.region != 0) {
            let checkedRegion = this.regions.find(item => item.ID == value.region)
            if (checkedRegion?.RegionName) {
               setTimeout(() => {
                  document.querySelector('#regionSelect input').value = checkedRegion.RegionName
               })
            }
            this.region = value.region
         }
         if (value.price1) this.price1 = Number(value.price1);
         if (value.price2) this.price2 = Number(value.price2);
         if (value.not_actual) this.not_actual = value.not_actual;
         if (value.cancel) this.cancel = value.cancel;
         if (value.kwords_minus) this.kwords_minus = value.kwords_minus;
         if (value.etc && value.etc != 0) {
            let checkedEtc = this.etcTenders.find(item => item.ID == value.etc)
            if (checkedEtc?.EtpName) {
               setTimeout(() => {
                  document.querySelector('#etcSelect input').value = checkedEtc?.EtpName
               })
            }
            this.etc = value.etc;
         }
         if (value.okpd2 && value.okpd2 !== '') this.okpd2 = value.okpd2;
         if (value.okpd && value.okpd !== '') this.okpd = value.okpd;
         if (value.f && value.f !== '') this.fzTypeChecked = value.f;


         if (this.$route.query.refresh) {
         }
         setTimeout(async () => {
            await this.GetSearch(0)

         }, 300)
      },
      CheckData(value) {
         var dateB = moment(String(value), "DD-MM-YYYY");
         var dateC = moment();
         var dif = Number(dateB.diff(dateC, "days"));

         if (dif >= 5) {
            return 1;
         } else if (dif < 5 && dif > 0) {
            return 2;
         }

         if (dif < 0 || dif == 0) {
            return 0;
         }
      },
      GetData(value) {
         var dateB = moment(String(value), "DD-MM-YYYY");
         var dateC = moment();

         var dif = dateB.diff(dateC, "days");
         if (dif < 0) {
            return "завершено";
         } else if (dif == 0) {
            return "сегодня завершится";
         } else {
            return (
               "до завершения:" + " " + dateB.diff(dateC, "days") + " " + "д."
            );
         }
      },
      async SaveRassilka(email_rassilka, name, id) {
         var object = {};

         if (!email_rassilka) {
            object.email_rassilka = true;
         } else {
            object.email_rassilka = false;
         }

         object.name = name;
         object.user = this.user.user;

         await axios
            .put(`/api/v1/template/item/${id}/`, object)
            .then((response) => {
               // console.log(response.data)

               var ind = this.templates.findIndex((item) => item.id === id);
               this.templates[ind].email_rassilka =
                  response.data.email_rassilka;

               this.GetTemplateItem(id);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      onKeyPress(e) {
         if (e.keyCode === 13) {
            this.GetSearch(this.current_page, true);
         }
      },
   },
   computed: {
      filterNumbers() {
         const range = [];

         if (this.page == this.page_max) {
            range.push({ num: 1 });
            range.push({ num: 2 });
            // range.push({ num: 3 })
            // range.push({ num: 4 })
            // range.push({ num: 5 })
         } else {
            for (
               let i = Number(this.current_page);
               i <=
               Math.min(
                  Number(this.current_page) + this.limit - 1,
                  this.page_max
               );
               i++
            ) {
               if (i != 0) {
                  range.push({
                     num: i,
                  });
               }
            }
         }

         return range;
      },
   },
   components: {
      vPopup,
      vSelect,
      etcSelect,
      regionSelect,
      otraslSelect,
      "profile-head": ProfileHeader,
      searchSelect
   },
   watch: {
      "$route.query.page"(value) {
         setTimeout(() => {
            this.page = Number(value);
         }, 100);
      },
      choosePage(value) {
         if (value > this.page_max || value < 0) {
            this.choosePage = this.page_max;
         }
      },
   },
   async created() {
      // await this.GetRegions();
      // await this.setFilters(this.GET_SEARCH_DATA());
      if (this.$route.query.page) {
         this.page = Number(this.$route.query.page);
         this.current_page = this.page;
         if (!this.templatePage) this.GetSearch(this.current_page);
      } else {
         this.current_page = 1;
         this.page = 1;
         if (!this.templatePage)
            (this.region = 0), (this.cat = 0), (this.etc = 0);
         // if (!this.templatePage) this.GetSearch(this.current_page)
      }

      moment.locale("ru");

      this.GetCategoriesTenders();
      this.GetEtcTenders();
      this.GetTemplates();
      this.getFavorFilters()
      window.addEventListener("keypress", this.onKeyPress);
   },
   watch: {
      "$route.query": {
         handler(value) {
            if (value.redirect == "templatePage") {
               this.templatePage = true;

               if (value.uid != undefined) {
                  this.curTemplate = Number(value.uid);
                  this.GetTemplateItem(this.curTemplate);
               } else {
                  this.curTemplate = 0;
                  this.templateItem = {};
               }
            } else {
               this.templatePage = false;
            }
         },
         deep: true,
         immediate: true,
      },
   },
   beforeUnmount() {
      this.CHANGE_SEARCH_DATA(this.ObjectData());
   },
   beforeDestroy() {
      window.removeEventListener("keypress", this.onKeyPress);
   },
};
</script>
<style scoped>
select {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
}

select::-ms-expand {
   display: none;
}

.select__main {
   position: relative;
}

select.not_active-select {
   color: #72757b;
}

select.active-select_color {
   color: #fff !important;
}

.emtpy-search_title {
   font-size: 32px;
   font-weight: 400;
   text-align: left;
}

.select__main::before {
   content: "";
   display: block;
   width: 24px;
   background: url("@/assets/img/icons/arrowDownSelect.svg") center/auto no-repeat;
   height: 24px;
   flex: 0 0 24px;
   transition: 0.3s;
   position: absolute;
   right: 5px;
   top: 40px;
}

@media (max-width: 576px) {
   .select__main::before {
      top: 34px;
   }
}

.gap_none {
   gap: 0px !important;
}

.template {
   padding: 8px 8px 8px 12px;
   cursor: pointer;
   transition: 0.3s;
}

.template.active {
   background: #ffd954;
   border-radius: 8px;
   color: #1e2024;
}

.template:hover {
   background: #2d2f33;
   color: #fff !important;
   border-radius: 8px;
}

.searchPage .search-footer__clear {
   transition: 0.3s;
}

.searchPage .search-footer__clear:hover {
   color: #ffd954;
}

.template.active .search__text {
   color: #1e2024;
}

.not_full-text {
   width: calc(100% - 30px);
   text-wrap: nowrap;
   overflow: hidden;
}

.templatePage .templates__list {
   gap: 5px;
}

.searchPage .template {
   height: auto !important;
   border: none !important;
}
</style>
