<template>
   <div class="changePassword modal authorization authorization__modal" id="changePassword" :class="{ 'open': modal }">
      <div class="forget1__content modal__content authorization__content">
         <div class="forget1__row authorization__row">
            <div class="forget1__title authorization__title">Изменить пароль</div>
            <a @click="modal = false" class="forget1__close modal__close authorization__close"></a>
         </div>
         <form @submit.prevent="ConfirmPassword()" class="changePassword__main">
            <!-- <div class="changePassword__input authorization__input">
                  <span>Введите текущий пароль</span>
                  <input v-model="cur_pas" type="password">
               </div> -->
            <div class="changePassword__input authorization__input show-pass__wrapper">
               <span>Введите новый пароль</span>
               <!-- пароль -->
               <input v-model="new_pas" :type="is_show_new_pas ? 'text' : 'password'">
               <button class="show-pass" @click.prevent="is_show_new_pas = !is_show_new_pas">
                  <img v-if="!is_show_new_pas" src="@/assets/img/icons/eye.svg" alt="">
                  <img v-else src="@/assets/img/icons/eye-off.svg" alt="">
               </button>
            </div>
            <div class="changePassword__input authorization__input show-pass__wrapper">
               <span>Повторите пароль</span>
               <!-- пароль -->
               <input v-model="new_pas2" :type="is_show_new_pas2 ? 'text' : 'password'">
               <button class="show-pass" @click.prevent="is_show_new_pas2 = !is_show_new_pas2">
                  <img v-if="!is_show_new_pas2" src="@/assets/img/icons/eye.svg" alt="">
                  <img v-else src="@/assets/img/icons/eye-off.svg" alt="">
               </button>
            </div>
            <div class="forget1__buttons" v-if="new_pas && (new_pas == new_pas2)">
               <a class="sign__btn btn gold modal__link" type="button" @click="ConfirmPassword()">Изменить</a>
            </div>
            <div class="forget1__buttons" v-else>
               <a class="sign__btn btn gold modal__link" type="button" disabled>Изменить</a>
            </div>
         </form>
      </div>
   </div>

   <SuccessModal :modal="success" subject="Пароль успешно изменен"
      description="Теперь нажмите “Войти“, чтобы попробовать еще раз." @close="success = $event" />
</template>

<style scoped>
/*.loader__pass h2 {
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    margin: 35px 0 15px;
    color: #1B1B1E;
}*/
</style>

<script>
import SuccessModal from '@/components/Modal/SuccessModal.vue'
import axios from 'axios'
import { toast } from 'bulma-toast'
import moment from 'moment';

export default {
   name: "ConfirmPassword",
   props: {
      modal: Boolean,
      uid: String,
      token: String,
   },
   components: {
      SuccessModal,
   },
   data() {
      return {
         cur_pas: "",
         new_pas: "",
         new_pas2: "",

         success: false,

         is_show_new_pas: false,
         is_show_new_pas2: false,
      }
   },
   beforeCreate() {
      this.$store.commit('initializeStore')
   },
   mounted() {

   },
   methods: {
      async ConfirmPassword() {
         const NewData = {
            "uid": this.uid,
            "token": this.token,
            "new_password": this.new_pas2
         }

         await axios
            .post(`/api/v1/users/reset_password_confirm/`, NewData)
            .then(response => {
               this.$emit('conf', false)
               this.success = true

               setTimeout(() => {
                  this.success = false
                  window.location.href = '/'
               }, 2500)
            })
            .catch(error => {
               console.log(error)
               alert('Данная ссылка не актуальна')
               this.$emit('conf', false)
               window.location.href = '/'
            })
      },
   },
   created() {

   },
   watch: {

   },
}
</script>