<template>
   <profile-head></profile-head>
   <main class="main">
      <section class="info">
         <div class="container">
            <div class="info__grid">
               <div class="profile card">
                  <div class="profile__title account-title">Профиль</div>
                  <div class="profile__row">
                     <ul class="profile__list">
                        <li class="profile__item">
                           <span>ФИО</span>
                           <input v-model="user.fio" type="text" />
                        </li>
                        <li class="profile__item">
                           <span>E-mail</span>
                           <input v-model="user.email" disabled type="email" />
                        </li>
                        <li class="profile__item">
                           <span>Телефон</span>
                           <input v-model="user.phone" type="tel" v-mask="'+# (###) ###-##-##'" />
                        </li>
                        <li class="profile__item">
                           <span>ИНН</span>
                           <input v-model="user.inn" type="tel" v-mask="'##################'" />
                        </li>
                     </ul>
                     <div class="profile__user profile-user">
                        <div class="profile-user__image">
                           <img v-if="user.get_image" :src="user.get_image" />
                           <img v-else src="@/assets/img/account/clear.png" alt="" />
                        </div>
                        <input type="file" accept="image/*" id="image" name="image" @change="onFileChange"
                           style="display: none" />
                        <label for="image" class="profile-user__edit">
                           Изменить
                        </label>
                     </div>
                  </div>
                  <div class="profile__buttons">
                     <a @click="UpdateData('data_user', true)" class="profile__btn btn">Сохранить</a>
                     <a @click="changeMail = true" class="profile__btn btn">Изменить E-mail</a>
                     <a @click="changePassword = true" class="profile__btn btn">Изменить пароль</a>
                  </div>
               </div>
               <div class="subscribe card active" :class="{
                  'not-payed': user.payment === false,
                  loading: !user.hasOwnProperty('payment'),
               }">
                  <div class="card__tags">
                     <h3 class="tag__title" :class="{ active: tag == 0 }" @click="tag = 0">
                        Текущая подписка
                     </h3>
                     <h3 class="tag__title" :class="{ active: tag == 1 }" @click="tag = 1">
                        Транзакции
                     </h3>
                  </div>
                  <div class="subscribe__title account-title" v-if="tag == 0 && Object.keys(user).length > 0">
                     <span>{{ sub.name }}</span> {{ DateSort(sub.duration) }}
                  </div>
                  <div class="subscribe__prefs" v-if="tag == 0">
                     <div class="subscribe__pref">
                        Количество запросов<br />
                        <span v-if="sub?.request_num == 9999">не ограничено</span>
                        <span v-else>
                           {{ sub?.request_num }}
                        </span>
                     </div>
                     <div class="subscribe__pref">
                        {{ sub.ustr }}
                     </div>
                  </div>
                  <div class="subscribe__row" v-if="tag == 0">
                     <div class="subscribe__auto">
                        <input v-model="autopay" type="checkbox" id="subscribeAuto" :disabled="!user.payment" />
                        <label for="subscribeAuto"></label>
                        <span>Автопродление</span>
                     </div>
                     <div class="subscribe__remind" v-if="
                        Object.keys(user).length > 0 &&
                        history.length != 0 &&
                        user.payment
                     ">
                        <span>Активна до:
                           {{
                              CorDateFrom(
                                 history[this.history.length - 1]?.transaction
                                    ?.transaction?.completed_datetime,
                                 sub.duration
                              )
                           }}
                        </span>
                     </div>
                     <div class="subscribe__pay" v-if="!user.payment" @click="mchooseSub = true">
                        Оплатите подписку
                     </div>
                  </div>
                  <div v-if="tag == 1">
                     <div class="transcation__row" v-for="item in history" :key="item">
                        <h3 class="text__trans light">
                           {{ item?.transaction?.transaction.custom_order_id }}
                        </h3>
                        <h3 class="text__trans light">
                           {{ item?.transaction?.transaction.amount }} ₽
                        </h3>
                        <h3 class="text__trans date">
                           {{
                              CorDate(
                                 item?.transaction?.transaction.created_datetime
                              )
                           }}
                        </h3>
                        <h3 class="text__trans status__ok" v-if="
                           item?.transaction?.transaction.state == 'COMPLETE'
                        ">
                           Оплачено
                        </h3>
                        <h3 class="text__trans status__nope" v-else>
                           Отменена
                        </h3>
                     </div>
                  </div>
               </div>
               <div class="promo card">
                  <div class="promo__title account-title">Промокод</div>
                  <div class="promo__row">
                     <input type="text" v-model="promo" name="name" class="promo__input"
                        placeholder="Введите промокод" />
                     <button ref="promoBtn" @click="openPromocodeModal" class="promo__btn btn">
                        Применить
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="actions">
         <div class="container">
            <div class="actions__row">
               <!-- <div class="actions-settings actions__card">
                  <div class="actions-feedback__title account-title">
                     Количество запросов
                  </div>
                  <div
                     class="requests-numbers__text"
                     v-if="countRequests.sub?.request_num == 9999"
                  >
                     Не ограничено
                  </div>
                  <div class="requests-numbers__text" v-else>
                     Всего {{ countRequests.sub?.request_num }} Осталось
                     {{ countRequests?.request_num }}
                  </div>
               </div> -->
               <div class="actions__card actions-settings profile-escort">
                  <EscortModal :modal="escrotForm" :userOn="true" @close="escrotForm = $event" />
                  <div class="hero__card escort__card">
                     <div class="hero__title">
                        Тендерное сопровождение — доверьте процесс участия в
                        закупках профессионалам
                     </div>
                     <footer class="hero__footer">
                        <button class="hero__btn btn" @click="escrotForm = true">
                           Оставить заявку
                        </button>
                     </footer>
                  </div>
               </div>
               <div class="actions-settings actions__card ovh-block" v-if="Object.keys(cards).length > 0">
                  <div class="row-cards card-title__font">
                     <div class="actions-settings__title">Мои карты</div>
                     <div class="add-new__card pointer">
                        <a @click="CardsModalOp(true, false)">
                           <img src="@/assets/img/icons/plus-card.svg" />
                           Добавить карту
                        </a>
                     </div>
                  </div>
                  <div class="row-items__cards">
                     <div class="item-card__user protender" v-for="list in cards" :key="list">
                        <div class="row-between__numb bottom__numb">
                           <span @click="deleteCard(list.id)" style="color: red; font-size: 9px" class="pointer">
                              удалить карту
                           </span>
                           <span @click="UpdateCard(list)" class="fff pointer">
                              изменить карту
                           </span>
                        </div>
                        <div class="row-between__numb bottom__numb">
                           <span>
                              {{
                                 replaceNumbers(
                                    list.number_card,
                                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                                 )
                              }}
                           </span>
                           <span>
                              {{ list.mth_card }}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="actions-settings actions__card ovh-block" v-else>
                  <div class="row-cards card-title__font">
                     <div class="actions-settings__title">
                        У вас нет привязанных карты
                     </div>
                  </div>
                  <div class="not-have__cards">
                     <div class="add-new__card pointer" @click="CardsModalOp(true, false)">
                        <a class="flex__titles">
                           <img src="@/assets/img/icons/plus-card.svg" />
                           Добавить карту
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section class="actions">
         <div class="container">
            <div class="actions__row">
               <form @submit.prevent="SendFormBack()" class="actions-feedback actions__card">
                  <div class="actions-feedback__title account-title">
                     Обратная связь
                  </div>
                  <div class="actions-feedback__desc action__text">
                     Напишите нам в случае возникновения вопросов
                  </div>
                  <ul class="actions-feedback__list">
                     <li class="actions-feedback__theme actions-feedback__item">
                        <span class="action__text">Тема</span>
                        <input v-model="subject" type="text" />
                     </li>
                     <li class="actions-feedback__message actions-feedback__item">
                        <span class="action__text">Текст сообщения</span>
                        <textarea v-model="description" name="description" id="" cols="30" rows="10">
                        </textarea>
                     </li>
                  </ul>
                  <button class="actions-feedback__btn btn" type="submit">
                     Отправить
                  </button>
               </form>
               <div class="actions-settings actions__card" ref="notificationSettings">
                  <div class="actions-settings__title account-title">
                     Настройка уведомлений
                  </div>
                  <div class="actions-settings__main">
                     <!-- <div class="actions-settings__mail" style="border: none !important">
                        <input type="checkbox" name="" v-model="user.notifcation" id="actionsSettingsMail"
                           @click="UpdateData('notification', true)" />
                        <label for="actionsSettingsMail"> </label>
                        <span class="action__text">Отправлять уведомления на почту за 5 дней до
                           завершения приема заявок на избранные закупки</span>
                     </div>
                     <div class="actions-settings__mail" style="border: none !important">
                        <input type="checkbox" name="" v-model="user.notifcation" id="actionsSettingsMail1"
                           @click="UpdateData('notification', true)" />
                        <label for="actionsSettingsMail1"> </label>
                        <span class="action__text">Отправлять уведомления на почту за 10 дней до
                           завершения приема заявок на избранные закупки</span>
                     </div> -->
                     <div class="actions-settings__mail" style="border: none !important">
                        <input type="checkbox" name="" v-model="notificationsSettings.days5" id="actionsSettingsMail" />
                        <label for="actionsSettingsMail"> </label>
                        <span class="action__text">Отправлять уведомления на почту за 5 дней до
                           завершения приема заявок на избранные закупки</span>
                     </div>
                     <div class="actions-settings__mail" style="border: none !important">
                        <input type="checkbox" name="" v-model="notificationsSettings.days10"
                           id="actionsSettingsMail1" />
                        <label for="actionsSettingsMail1"> </label>
                        <span class="action__text">Отправлять уведомления на почту за 10 дней до
                           завершения приема заявок на избранные закупки</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>

   <card-modal :modal="cardmodal" :edit="editCard" :card="currentCard"
      @close="(cardmodal = $event), (editCard = $event), (currentCard = {})" @status="updateCards = $event">
   </card-modal>

   <div class="changeMail modal authorization authorization__modal" id="changeMail" :class="{ open: changeMail }"
      @click="changeMail = false">
      <div class="forget1__content modal__content authorization__content" @click.stop="">
         <div class="forget1__row authorization__row">
            <div class="forget1__title authorization__title">
               Изменить E-mail
            </div>
            <a @click="changeMail = false" class="forget1__close modal__close authorization__close"></a>
         </div>
         <div class="forget1__text">
            Для смены E-mail, все поля обязательны к заполнению*
         </div>
         <div class="forget1__main">
            <div class="forget1__input authorization__input">
               <span>Введите новый E-mail</span>
               <input v-model="new_email" type="email" />
            </div>
            <div class="forget1__input authorization__input">
               <span>Введите текущий пароль</span>
               <input v-model="current_password_em" type="email" />
            </div>
            <div class="forget1__buttons">
               <a @click="UpdateEmail()" class="sign__btn btn gold modal__link">Изменить</a>
            </div>
         </div>
      </div>
   </div>

   <div class="changePassword modal authorization authorization__modal" id="changePassword"
      :class="{ open: changePassword }" @click="changePassword = false">
      <div class="forget1__content modal__content authorization__content" @click.stop="">
         <div class="forget1__row authorization__row">
            <div class="forget1__title authorization__title">
               Изменить пароль
            </div>
            <a @click="changePassword = false" class="forget1__close modal__close authorization__close"></a>
         </div>
         <div class="changePassword__main">
            <div class="changePassword__input authorization__input show-pass__wrapper">
               <span>Введите текущий пароль</span>
               <!-- пароль -->
               <input v-model="cur_pas" :type="is_show_cur_pas ? 'text' : 'password'" />
               <button class="show-pass" @click.prevent="is_show_cur_pas = !is_show_cur_pas">
                  <img v-if="!is_show_cur_pas" src="@/assets/img/icons/eye.svg" alt="">
                  <img v-else src="@/assets/img/icons/eye-off.svg" alt="">
               </button>
            </div>
            <div class="changePassword__input authorization__input show-pass__wrapper">
               <span>Введите новый пароль</span>
               <!-- пароль -->
               <input v-model="new_pas" :type="is_show_new_pas ? 'text' : 'password'" />
               <button class="show-pass" @click.prevent="is_show_new_pas = !is_show_new_pas">
                  <img v-if="!is_show_new_pas" src="@/assets/img/icons/eye.svg" alt="">
                  <img v-else src="@/assets/img/icons/eye-off.svg" alt="">
               </button>
            </div>
            <div class="changePassword__input authorization__input show-pass__wrapper">
               <span>Повторите пароль</span>
               <!-- пароль -->
               <input v-model="new_pas2" :type="is_show_new_pas2 ? 'text' : 'password'" />
               <button class="show-pass" @click.prevent="is_show_new_pas2 = !is_show_new_pas2">
                  <img v-if="!is_show_new_pas2" src="@/assets/img/icons/eye.svg" alt="">
                  <img v-else src="@/assets/img/icons/eye-off.svg" alt="">
               </button>
            </div>
            <div class="forget1__buttons" v-if="cur_pas && new_pas && new_pas == new_pas2">
               <a class="sign__btn btn gold modal__link" type="button" @click="NewPassword()">Изменить</a>
            </div>
            <div class="forget1__buttons" v-else>
               <a class="sign__btn btn gold modal__link" type="button" disabled>Изменить</a>
            </div>
         </div>
      </div>
   </div>

   <div class="modal authorization authorization__modal modal-rate" :class="{ open: mchooseSub }">
      <div class="forget1__content modal__content authorization__content subs__modal modal-rate__content">
         <div class="forget1__row authorization__row subs__row">
            <div class="forget1__title authorization__title">
               Выберите подходящий тариф
            </div>
            <a @click="mchooseSub = false" class="forget1__close modal__close authorization__close"></a>
         </div>
         <div class="rate__card" ref="subs">
            <div class="rate__row modal-rate__row">
               <div class="rate__item rate-item" v-for="(item, index) in subs" :key="index">
                  <div class="rate-item__image">
                     <img v-if="item.get_image" :src="item.get_image" alt="" />
                  </div>
                  <div class="rate-item__period">
                     <span>{{ item.name }}</span> {{ DateSort(item.duration) }}
                  </div>
                  <div class="register-selected__price" v-if="current_price != 0 && crtarif == item.id">
                     {{ numberWithSpaces(current_price) }}₽
                     <s>{{ numberWithSpaces(item.price) }} ₽</s>
                  </div>
                  <div class="register-selected__price" v-else>
                     {{ numberWithSpaces(item.price) }} ₽
                  </div>
                  <div class="rate-item__conditions">
                     <span>Количество
                        <b v-if="item?.request_num == 9999">
                           запросов не ограничено</b>
                        <b v-else> {{ item?.request_num }} запросов</b>
                     </span>
                     <span>{{ item.ustr }}</span>
                  </div>
                  <a @click="PaymentSend(item)" class="rate-item__button btn">Подключить тариф</a>
                  <span class="rate-item__bonus">
                     <router-link to="/escort">Подключить с сопровождением</router-link>
                     +15 000 ₽</span>
                  <span class="rate-item__bonus rate-item__bonus_adapt"><router-link to="/escort">Подключить с <br />
                        сопровождением</router-link>
                     +15 000 ₽</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";
import { toast } from "bulma-toast";
import moment from "moment";

import ProfileHeader from "@/components/Header/ProfileHeader";
import CardModal from "@/components/Modal/Cards/Card";
import EscortModal from "@/components/Modal/Escort";

export default {
   name: "Profile",
   directives: { mask },
   data() {
      return {
         autopay: false,
         name: "",
         phone: "+7",
         email: "",
         inn: "",
         imgProfile: "",

         changeMail: false,
         changePassword: false,

         cur_pas: "",
         new_pas: "",
         new_pas2: "",

         image: "",

         user: {},

         transaction_id: "",

         // FormBack

         subject: "",
         description: "",

         tag: 0,

         history: [],
         sub: {},

         new_email: "",
         current_password_em: "",

         mchooseSub: false,

         subs: [],

         cardmodal: false,
         edit: false,

         promo: "",

         alertsPromo: [],

         crtarif: 0,

         current_price: 0,

         id: 0,

         countRequests: {},

         newSub_id: 0,

         paymentSuccess: false,

         updateCards: false,
         cards: [],
         editCard: false,
         currentCard: {},

         escrotForm: false,



         is_show_cur_pas: false,
         is_show_new_pas: false,
         is_show_new_pas2: false,
         notificationsSettings: {}
      };
   },
   beforeCreate() {
      this.$store.commit("initializeStore");
   },
   mounted() {
      if (this.$route.query.redirect) {
         // console.log(this.$route.query.redirect)
         setTimeout(() => {
            this.$refs[this.$route.query.redirect].scrollIntoView({
               behavior: "smooth",
               block: "start",
            });
         }, 100);
      }
      if (this.$route.query.transaction_id) {
         this.PostHistoryTransition(this.$route.query.transaction_id);
      }
      // if (this.user.payment == false) {
      //    this.autopay = false
      // }
   },
   methods: {
      async getNotifications() {
         let { data } = await axios.get('/api/v1/notifications/settings')
         let obj = {
            days5: data[0].days5,
            days10: data[0].days10,
         }
         this.notificationsSettings = obj
         console.log('get notifications settings', data);

      },
      async changeNotificationsSettings() {
         const formdata = new FormData();
         formdata.append("days5", Number(this.notificationsSettings.days5));
         formdata.append("days10", Number(this.notificationsSettings.days10));
         let data = await axios.post('/api/v1/notifications/settings', formdata)
         console.log('post notifications settings', data);
      },
      async getCountRequestSub() {
         await axios
            .get(`/api/v1/get/count/requests/sub/`)
            .then((res) => {
               // console.log(res.data.status);
               if (res.data.status == "not_exist") {
                  setTimeout(() => {
                     this.createModel();
                  }, 300);
               } else {
                  this.countRequests = res.data;
               }
            })
            .catch((err) => {
               console.log(err);
            });
      },
      async deleteCard(uid) {
         await axios
            .delete(`/api/v1/delete/card/${uid}/`)
            .then((response) => {
               toast({
                  message: "Карта успешно удалена",
                  type: "is-success",
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: "bottom-right",
               });

               this.GetCards();
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async createModel() {
         const DataNew = {
            user: this.user.user,
            request_num: this.sub?.request_num,
            sub: this.user.sub,
         };
         // console.log("Create Model: ", DataNew);
         await axios
            .post(`/api/v1/post/count/requests/sub/`, DataNew)
            .then((res) => {
               // console.log(res.data);
               window.location.reload();
            })
            .catch((err) => {
               console.log(err);
            });
      },
      async PromoUsed(value) {
         await axios
            .get(`/api/v1/promo/used/${value}/`)
            .then((response) => {
               // console.log(response.data)
            })
            .catch((error) => {
               console.log(error);
            });
      },
      openPromocodeModal() {
         // if (this.promo) {
         //    this.mchooseSub = true
         // }
         this.CheckPromo();
      },
      async PaymentSend(item) {
         const paymentData = {};
         paymentData.email = this.email;
         paymentData.indificator = item.indificator;
         if (this.current_price != 0 && this.crtarif == item.id)
            paymentData.price = this.current_price;
         else paymentData.price = item.price;

         // console.log(paymentData)

         await axios
            .post("/api/v1/post/payment/", paymentData)
            .then((response) => {
               if (this.current_price != 0) this.PromoUsed(this.promo);
               // console.log(response.data)
               window.location.href = response.data;
            })
            .catch((error) => {
               console.log(error);
               toast({
                  message: "Повторите попытку позже",
                  type: "is-success",
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: "bottom-right",
               });
            });
      },
      UpdateCard(obj) {
         this.currentCard = obj;
         this.editCard = true;
         this.cardmodal = true;
      },
      async CheckPromo() {
         this.prom = {};

         await axios
            .get(`/api/v1/promocode/${this.promo}/`)
            .then((response) => {
               this.prom = response.data.body;
               this.alertsPromo = [];

               if (Number(response.data?.request_num) == 0) {
                  this.error_promo.push("Срок действия промокода закончился.");
               }
               if (response.data.status == "none") {
                  this.alertsPromo.push("Промокода не существует.");
               } else {
                  if (Number(response.data.body?.request_num) == 0)
                     this.alertsPromo.push(
                        "Срок действия промокода закончился."
                     );
                  else {
                     this.mchooseSub = true;
                     this.alertsPromo.push("Промокод успешно активирован.");
                     this.crtarif = response.data.body.sub;
                     var objsub = this.subs.find(
                        (item) => item.id === this.crtarif
                     );

                     if (response.data.body.percent == 100)
                        this.current_price = 1;
                     else
                        this.current_price =
                           (parseInt(objsub.price) *
                              (100 - response.data.body.percent)) /
                           100;

                     // console.log(this.current_price)
                  }
               }
               toast({
                  message: this.alertsPromo[0],
                  type: "is-success",
                  dismissible: true,
                  pauseOnHover: true,
                  closeOnClick: true,
                  position: "bottom-right",
               });
            })
            .catch((error) => {
               this.alertsPromo.push("Ошибка.");
            });
      },
      async GetMeInfo() {
         await axios
            .get("/api/v1/me/")
            .then((response) => {
               console.log("user from server", response.data);
               this.user = response.data;
               this.autopay = this.user.autopay;
               this.GetSubUser(response.data.sub);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetHistoryTransaction() {
         await axios
            .get("/api/v1/history/")
            .then((response) => {
               this.history = response.data.results;
               setTimeout(() => {
                  let lastPayment = this.history[this.history.length - 1];
                  let trans = lastPayment?.transaction?.transaction;
                  let endPaymentDate = new Date(
                     this.CorDateFrom(
                        trans?.completed_datetime,
                        this.sub.duration
                     )
                        .split(".")
                        .reverse()
                        .join(",")
                  );
                  let dateNow = new Date();
                  let remind = endPaymentDate - dateNow;
                  // console.log("endPaymentDate", endPaymentDate);
                  // console.log("dateNow", dateNow);
                  // if (this.user.payment == false) {

                  //    if (remind > 0) {
                  //       this.paymentSuccess = true;
                  //       console.log("payment true")
                  //       this.UpdateData("payment")
                  //    }
                  // }
                  // if (this.user.payment == true) {
                  //    if (remind < 0) {
                  //       this.paymentSuccess = false;
                  //       this.UpdateData("payment")
                  //    }
                  // }
                  // if (remind > 0) {
                  //    this.paymentSuccess = true;
                  //    this.UpdateData("payment", true)
                  // } else {
                  //    this.paymentSuccess = false;
                  //    this.UpdateData("payment", true)
                  // }
               }, 500);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetSubUser(value) {
         await axios
            .get(`/api/v1/get/sub/${value}/`)
            .then((response) => {
               this.sub = response.data;
               this.getCountRequestSub();
            })
            .catch((error) => {
               console.log(error);
            });
      },
      onFileChange(e) {
         this.image = event.target.files[0];
         var files = e.target.files || e.dataTransfer.files;
         if (!files.length) return;
         this.UpdateData("only_image", true);
      },
      async UpdateData(value, withNoToast = false) {
         const headersUpload = { "Content-Type": "multipart/form-data" };

         console.log(this.user.notifcation);

         let obj = {
            // payment: this.user.payment,
            notifcation: this.user.notifcation,
            autopay: this.autopay,
         };

         if (String(value) == "only_image") {
            obj.email = this.user.email;
            obj.image = this.image;
         }
         if (String(value) == "notification") {
            obj.email = this.user.email;
            // if (!this.user.notifcation) obj.notifcation = true
            // else obj.notifcation = false
            obj.notifcation = !this.user.notifcation;
         }
         if (String(value) == "autopay") {
            obj.email = this.user.email;
            obj.autopay = this.autopay;
         }
         if (String(value) == "only_email") {
            obj.email = this.new_email;
         }

         if (String(value) == "data_user") {
            obj.email = this.user.email;
            obj.fio = this.user.fio;
            obj.phone = this.user.phone;
            obj.inn = this.user.inn;
         }
         if (String(value) == "paymentsub_id") {
            obj.email = this.user.email;
            // obj.payment = this.paymentSuccess
            obj.sub = this.newSub_id;
         }
         if (String(value) == "payment") {
            obj.email = this.user.email;
            // obj.payment = this.paymentSuccess
         }

         console.log("update data object send", obj);
         await axios
            .put("/api/v1/me/", obj, { headers: headersUpload })
            .then((response) => {
               if (withNoToast) {
                  toast({
                     message: "Данные успешно обновлены!",
                     type: "is-success",
                     dismissible: true,
                     pauseOnHover: true,
                     closeOnClick: true,
                     position: "bottom-right",
                  });
               }

               this.user = response.data;

               localStorage.setItem("user", response.data);

               this.$store.commit("setUserInfo", response.data);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      GoToRef(val) {
         this.$refs[val].scrollIntoView({ behavior: "smooth" });
      },
      async GetSubs() {
         await axios
            .get("/api/v1/subs/")
            .then((response) => {
               this.subs = response.data;
               // console.log(response.data)
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetCards() {
         await axios
            .get("/api/v1/multi/cards/")
            .then((response) => {
               this.cards = response.data;
               console.log("cards test", response.data);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async SendFormBack() {
         const DataNew = {
            email: this.user.email,
            fio: this.user.fio,
            phone: this.user.phone,
            subject: this.subject,
            description: this.description,
            date_added_public: new Date()

         };

         await axios
            .post("/api/v1/post/form/back/", DataNew)
            .then((response) => {
               toast({
                  message: "Успешно отправлено",
                  type: "is-success",
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: "bottom-right",
               });

               this.subject = "";
               this.description = "";

               // console.log(response.data)
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async PostHistoryTransition(value) {
         const DataNew = {
            user: this.user.user,
            transaction_id: value,
         };
         console.log("just payed");
         await axios
            .post(`/api/v1/info/payment/${value}/`, DataNew)
            .then((response) => {
               console.log("PostHistoryTransition: ", response.data);
               if (response.data.status != "exist") {
                  toast({
                     message: "Оплата прошла успешно",
                     type: "is-success",
                     dismissible: true,
                     pauseOnHover: true,
                     duration: 3000,
                     position: "bottom-right",
                  });
                  // this.user.payment = true;
                  this.paymentSuccess = true;
                  this.UpdateData("paymentsub_id");

                  console.log("just payed first ");

                  this.GetHistoryTransaction();
                  // console.log("amount: ", Number(response.data.transaction.transaction.amount));
                  if (
                     Number(response.data?.transaction?.transaction.amount) == 1
                  ) {
                     this.RefundPayment(value);
                  }
                  if (response.data.transaction.status == "ok") {
                     console.log("autopay = true");
                     let transaction =
                        response.data?.transaction?.transaction.custom_order_id;
                     this.autopay = true;
                     this.UpdateData("autopay");
                     this.subs.forEach((item) => {
                        if (item.indificator == transaction) {
                           this.newSub_id = item.id;
                           // this.UpdateData("sub_id")
                           // this.user.payment = true;
                           this.paymentSuccess = true;

                           this.UpdateData("paymentsub_id");
                           this.GetMeInfo();
                        }
                     });
                  }
               }
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async RefundPayment(value) {
         const DataNew = {
            transaction_uid: value,
         };

         await axios
            .post(`/api/v1/post/refund/payment/`, DataNew)
            .then((res) => {
               // console.log("RefundPayment", res.data)
            })
            .catch((err) => {
               console.log(err);
            });
      },
      async NewPassword() {
         const DataNew = {
            new_password: this.new_pas,
            current_password: this.cur_pas,
         };

         await axios
            .post("/api/v1/users/set_password/", DataNew)
            .then((response) => {
               toast({
                  message: "Пароль успешно сменен!",
                  type: "is-success",
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: "bottom-right",
               });

               this.cur_pas = "";
               this.new_pas = "";
               this.new_pas2 = "";
               this.changePassword = false;

               // console.log(response.data)
            })
            .catch((error) => {
               if (error.response.data.current_password) {
                  toast({
                     message: "Неравильный текущий пароль!",
                     type: "is-success",
                     dismissible: true,
                     pauseOnHover: true,
                     duration: 3000,
                     position: "bottom-right",
                  });
               }
            });
      },
      async NewEmail() {
         const DataNew = {
            email: this.new_email,
         };

         await axios
            .put("/api/v1/users/me/", DataNew)
            .then((response) => {
               this.changeMail = false;
               this.UpdateData("only_email", true);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async UpdateEmail() {
         const DataNew = {
            new_username: this.new_email,
            current_password: this.current_password_em,
         };

         await axios
            .post("/api/v1/users/set_username/", DataNew)
            .then((response) => {
               this.NewEmail();
            })
            .catch((error) => {
               console.log(error);
            });
      },
      CardsModalOp(value, edit) {
         this.cardmodal = value;
         this.edit = edit;
      },
      async addCart() {
         await this.sendTestPayment()
      },
      async sendTestPayment() {
         let formData = {
            price: 1,
         }
         try {
            let res = await axios.post("/api/v1/post/payment/", formData)
            console.log('card data', res);
            if (res.data) {
               window.location.href = res.data;
            }
         } catch (err) {
            console.log(err);
            toast({
               message: "Повторите попытку позже",
               type: "is-success",
               dismissible: true,
               pauseOnHover: true,
               duration: 3000,
               position: "bottom-right",
            });
         }
      },
      CorDateFrom(date, days) {
         return moment(String(date), "YYYY-MM-DD")
            .add("days", days)
            .format("DD.MM.YYYY");
      },
      CorDate(date) {
         return moment(String(date), "YYYY-MM-DD").format("DD.MM.YYYY");
      },
      DateSort(value) {
         const item = Math.round(moment.duration(value, "days").asMonths());
         var result = item + " " + "месяцев";
         if (item / 12 == 1) result = "1 год";
         if (item == 3) result = item + " " + "месяца";
         if (item == 1) result = item + " " + "месяц";
         return result;
      },
      numberWithSpaces(x) {
         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      replaceNumbers(str, matches) {
         let count = 0;
         return str.replace(/\d/g, (d) =>
            matches.includes(++count) ? "*" : d
         );
      },
   },
   components: {
      EscortModal,
      "profile-head": ProfileHeader,
      "card-modal": CardModal,
   },
   watch: {
      notificationsSettings: {
         async handler(value) {
            await this.changeNotificationsSettings()
         },
         deep: true,
      },
      "$route.query.redirect"(value) {
         setTimeout(() => {
            this.$refs[value].scrollIntoView({
               behavior: "smooth",
               block: "start",
            });
         }, 100);
      },
      autopay(value) {
         this.user.autopay = value;
         this.UpdateData("autopay");
      },
      promo(value) {
         this.promo = value;
         this.current_price = 0;

         if (this.promo == "none") {
            this.promo = "";
         }
         if (value.length > 3) {
            this.$refs.promoBtn.classList.add("active");

            // console.log(this.$refs.promoBtn);
         } else {
            this.$refs.promoBtn.classList.remove("active");
         }
      },
      user() {
         console.log("payment", this.user.payment);
      },
      updateCards() {
         if (this.updateCards == true) this.GetCards();

         setTimeout(() => {
            this.updateCards = false;
         }, 510);
      },
      // notificationsSettings: {
      //    async handler(value) {
      //       console.log(value);
      //       await this.changeNotificationsSettings()
      //    },
      //    deep: true
      // }
   },
   async created() {
      await this.GetMeInfo();
      await this.GetHistoryTransaction();
      await this.GetSubs();
      await this.GetCards();


      // let formData = {
      //    days5: true,
      //    days10: true,
      //    user: this.user.user
      // }
      // console.log('formdata', formData);
      // const formdata = new FormData();
      // formdata.append("days10", "0");
      // formdata.append("days5", "1");
      // let data = await axios.post('/api/v1/notifications/settings', formdata)
      // console.log('post notifications settings', data);


      await this.getNotifications()



   },
};
</script>
<style type="scss">
.changePassword__main {
   width: 404px;
}

.changePassword__input {
   margin-bottom: 24px;
}

.accountPage .subscribe__pref span {
   display: contents;
}

@media (max-width: 576px) {
   .accountPage .subscribe__pref span {
      display: block;
   }

   .accountPage .subscribe__pref {
      gap: 3px !important;
   }
}

.card__tags {
   width: 100%;
   display: flex;
   align-items: flex-start;
   border-bottom: 1px solid #3f4144;
}

.tag__title {
   cursor: pointer;
   display: flex;
   padding: 8px 12px;
   align-items: flex-start;
   gap: 8px;
   color: #fff;
   font-size: 15px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   /* 160% */
}

.tag__title.active {
   border-bottom: 2px solid #ffd954 !important;
}

.text__trans.light {
   color: #fff;
   font-weight: 500;
}

.text__trans.date {
   color: #72757b;
}

.text__trans.status__ok {
   color: #0a9359;
}

.text__trans.status__nope {
   color: #e76014;
}

.subs__modal {
   width: max-content;
}

.subs__row {
   margin-bottom: 66px !important;
}

.transcation__row {
   display: flex;
   width: 100%;
   margin: 0 0 8px;
   padding: 10px 16px;
   justify-content: space-between;
   align-items: flex-start;
   border-radius: 8px;
   background: #2d2f33;
}

.actions-settings__mail .action__text {
   font-size: 13px !important;
}

textarea {
   resize: none;
}

.row-cards {
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding: 0 5px 10px;
   border-bottom: 1px solid #3f4144;
}

.card-title__font {
   color: #fff;
   font-variant-numeric: lining-nums tabular-nums;
   font-size: 15px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
}

.pointer {
   cursor: pointer;
}

.row-items__cards {
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
}

.item-card__user {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   flex: 0 0 266px;
   width: 266px;
   height: 156px;
   border-radius: 20px;
   position: relative;
   padding: 16px;
   margin: 0 15px 0 0px;
}

.item-card__user span {
   color: #fff;
   font-variant-numeric: lining-nums tabular-nums;
   font-size: 13px;
   font-style: normal;
   font-weight: 500;
   line-height: 20px;
}

.row-items__cards {
   overflow-x: auto;
}

.sber {
   background: #1bbe7a;
}

.protender {
   background: #2d2f33;
}

.row-between__numb {
   width: 100%;
   display: flex;
   justify-content: space-between;
}

.bottom__numb {}

.fff {
   font-size: 9px !important;
   /*   color: #2D2F33 !important;*/
}

.ovh-block {
   width: 100%;
   overflow: hidden;
}

.requests-numbers__text {
   color: #fff;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 40px;
   letter-spacing: -0.72px;
}

.not-have__cards {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   padding: 60px 0px 70px;
}

.flex__titles {
   line-height: 24px;
}
</style>
