import { createStore } from "vuex";

export default createStore({
   state: {
      isAuthenticated: false,
      token: "",
      user_id: "",
      sub_id: "",
      user: {},
      status_pay: false,
      isLoading: false,
      searchData: {},
      lastSearchPhrases: [],
   },
   mutations: {
      SAVE_LAST_SEARCH_PHRASES(state, data) {
         if (!data.length) return;
         if (state.lastSearchPhrases.includes(data)) return;
         state.lastSearchPhrases.unshift(data);
         if (state.lastSearchPhrases.length > 5) {
            state.lastSearchPhrases.length = 5;
         }
      },
      CHANGE_SEARCH_DATA(state, data) {
         state.searchData = data;
      },
      initializeStore(state) {
         if (localStorage.getItem("token")) {
            state.token = localStorage.getItem("token");
            state.isAuthenticated = true;
         } else {
            state.token = "";
            state.isAuthenticated = false;
         }

         if (localStorage.getItem("user_id")) {
            state.user_id = localStorage.getItem("user_id");
         } else {
            state.user_id = "";
         }

         if (localStorage.getItem("user")) {
            state.user = JSON.parse(localStorage.getItem("user"));
         } else {
            localStorage.setItem("user", JSON.stringify(state.user));
         }

         if (localStorage.getItem("sub_id")) {
            state.sub_id = localStorage.getItem("sub_id");
         } else {
            state.sub_id = "";
         }

         if (localStorage.getItem("id_pay")) {
            state.id_pay = localStorage.getItem("id_pay");
         } else {
            state.id_pay = "";
         }

         if (localStorage.getItem("status_pay")) {
            state.status_pay = localStorage.getItem("status_pay");
         } else {
            state.status_pay = false;
         }
      },
      setIsLoading(state, status) {
         state.isLoading = status;
      },
      removeSub(state) {
         state.sub_id = "";
      },
      removeUser(state) {
         state.user_id = "";
      },
      removePayID(state) {
         state.id_pay = "";
      },
      removePayStatus(state) {
         state.status_pay = false;
      },
      removeUserInfo(state) {
         state.user = {};
      },
      setUserInfo(state, user) {
         state.user = user;
         localStorage.setItem("user", JSON.stringify(state.user));
      },
      setUser(state, user_id) {
         state.user_id = user_id;
      },
      setSub(state, sub_id) {
         state.sub_id = sub_id;
      },
      setPayID(state, id_pay) {
         state.id_pay = id_pay;
      },
      setPayStatus(state, status_pay) {
         state.status_pay = status_pay;
      },
      setToken(state, token) {
         state.token = token;
         state.isAuthenticated = true;
      },
      removeToken(state) {
         state.token = "";
         state.isAuthenticated = false;
         state.anketaCheck = false;
      },
      clearCart(state) {
         state.cart = { items: [] };

         localStorage.setItem("cart", JSON.stringify(state.cart));
      },
   },
   getters: {
      GET_SEARCH_DATA(state) {
         return state.searchData;
      },
      AUTH(state) {
         return state.isAuthenticated;
      },
      GET_LAST_PHRASES(state) {
         return state.lastSearchPhrases;
      },
   },
   actions: {
      // saveSearchData({ commit }, data) {
      //    commit("changeSearchData", data);
      //    console.log("asdasda");
      // }
   },
   modules: {},
});
