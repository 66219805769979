<template>
   <div class="search-hidden__item big select-item__wrapper" id="searchInput">
      <span class="search__text">Поиск</span>
      <input @keyup.enter="isOpen = false" @focus="isOpen = true" :value="modelValue"
         @input="$emit('update:modelValue', $event.target.value)"
         placeholder="Введите название закупки через пробел. Например: компьютер программное обеспечение ПО" type="text"
         class="search__input" />
      <ul class="select-item__list" v-if="isOpen && filtered.length">
         <li v-for="list in filtered" :key="list" @click="selectOption(list)">
            {{ list }}
         </li>
      </ul>
   </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
const props = defineProps({
   modelValue: {
      type: String,
      default: "",
   },
   history: {
      type: Array,
      default: () => {
         return []
      }
   }
})
const emit = defineEmits(["update:modelValue"])


const selectOption = (item) => {
   emit('update:modelValue', item)
   isOpen.value = false;
}
const isOpen = ref(false)
console.log(props.history);
const filtered = computed(() => {
   return props.history.filter((item) => item.includes(props.modelValue))
})


onMounted(() => {
   document.addEventListener("click", (e) => {
      if (!e.target.closest("#searchInput")) {
         isOpen.value = false;
      }
   });
});
</script>