<template>
<footer class="footer">
   <div class="container">
      <div class="footer__card">
         <div class="footer__column">
            <div class="footer__grid">
               <router-link to="/" class="footer__logo">
                  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M28.3806 9.27218H19.6084V0.5M28.3806 20.1084H19.6084V28.8806M0 9.27218H8.77218V0.5M0 20.1084H8.77218V28.8806"
                        stroke="white" stroke-width="3.61207" />
                  </svg>
                  <span>PROTENDER</span>
               </router-link>
               <ul class="footer__list">
                  <li>
                     <a @click="GoLinkRef('preferences')" class="footer__link">О нас</a>
                  </li>
                  <li>
                     <a @click="GoLinkRef('oportunity')" class="footer__link">
                        Наши возможности</a>
                  </li>
                  <li>
                     <a @click="GoLinkRef('subs')" class="footer__link">
                        Тарифы</a>
                  </li>
                  <li>
                     <a @click="GoLinkRef('preim')" class="footer__link">
                        Преимущества</a>
                  </li>
                  <li>
                     <router-link to="/escort" class="footer__link">
                        Сопровождение</router-link>
                  </li>
               </ul>
               <a href="mailto:i@protender.pro" class="footer__mail">i@protender.pro</a>
            </div>
            <div class="footer-copy">
               <div class="footer-copy__brand">© PROTENDER, 2023</div>
               <a href="https://protender.pro/terms_of_use_protender" target="_blank" class="footer-copy__politic">Политика обработки персональных данных
               </a>
               <a href="https://protender.pro/privacy_policy_protender" target="_blank" class="footer-copy__politic">Пользовательское соглашение</a>
            </div>
         </div>
      </div>
   </div>
</footer>
</template>

<style scoped>

</style>

<script>
	export default {
		name: 'Footer',
		props: {
			fone: {
				type: Boolean,
				default: true,
			},
			fone_profile: {
				type: Boolean,
				default: false,				
			},
		},
      methods: {
           GoLinkRef(value) {
               this.$router.push({path: '/', query: { redirect: value }})
           },
      },
	}
</script>