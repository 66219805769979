<template>
   <div class="wrapper lock-padding" :class="className">
      <router-view />
      <Footer />
      <vue-progress-bar />
      <div id="oneum-chat"></div>
   </div>
</template>

<style lang="scss">
@import "@/assets/css/style.css";
@import "@/assets/css/new.scss";
</style>

<script>
// import '@/assets/js/app.min.js';

import Footer from "@/components/Footer/Footer";
import axios from "axios";
import { onMounted, ref } from "@vue/runtime-core";

export default {
   data() {
      return {
         className: "",
      };
   },
   components: {
      Footer,
   },
   beforeCreate() {
      this.$store.commit("initializeStore");

      const token = this.$store.state.token;

      if (token) {
         axios.defaults.headers.common["Authorization"] = "Token " + token;
      } else {
         axios.defaults.headers.common["Authorization"] = "";
      }
   },
   mounted() {
      this.$Progress.finish();

      this.setClass(this.$route.name);

      document.title =
         "PROTENDER – Самая подробная аналитика тендеров в России";
   },
   created() {
      this.$Progress.start();

      this.$router.beforeEach((to, from, next) => {
         if (to.meta.progress !== undefined) {
            let meta = to.meta.progress;

            this.$Progress.parseMeta(meta);
         }

         this.$Progress.start();

         next();
      });

      this.$router.afterEach((to, from) => {
         this.$Progress.finish();
      });
   },
   methods: {
      setClass(value, query) {
         if (value == "Favorites") this.className = "favoritesPage";
         if (value == "Profile") this.className = "accountPage";
         if (value == "home") this.className = "mainPage";
         if (value == "Escort") this.className = "escortPage";
         if (value == "VideoCourses") this.className = "videocoursePage";
         if (value == "Instructions") this.className = "instructionsPage";
         if (value == "InstructionMain")
            this.className = "instructionsPage OniumLeave";
         if (value == "TenderDetail") this.className = "tenderPage";
         if (value == "Lead") this.className = "lid-page";
         if (value == "ContragentDetail") this.className = "contrAgentPage";
         if (value == "EscortMain") this.className = "escortPage OniumLeave";
         if (query == "templatePage") this.className = "templatePage";
         if (value == "Search" && query == undefined)
            this.className = "searchPage";
      },
   },
   computed: {},
   watch: {
      $route(value) {
         if (value.query.redirect)
            this.setClass(value.name, value.query.redirect);
         else this.setClass(value.name);
      },
   },
};
</script>
