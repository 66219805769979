<template lang="">
   <div class="v-select" @click="toggleOptions">
      <div class="v-select__backdrop"></div>
      <div
         class="v-select__wrapper"
         :class="{ placeholder: this.settings.placeholder }"
         ref="output"
      >
         <span class="v-select__input">{{ this.choosedValue }}</span>
         <div class="v-select__icon">
            <svg
               xmlns="http://www.w3.org/2000/svg"
               width="22"
               height="22"
               viewBox="0 0 22 22"
               fill="none"
            >
               <path
                  d="M5.5 9.25L11 14.75L16.5 9.25"
                  stroke="#00A099"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
               />
            </svg>
         </div>
      </div>
      <ul class="v-select__list">
         <li
            class="v-select__item"
            :class="{ active: option.name === this.choosedValue }"
            v-for="(option, index) in this.settings.options"
            :key="index"
            @click="selectOption(option)"
         >
            {{ option.name }}
         </li>
      </ul>
   </div>
</template>
<script>
export default {
   name: "v-select",
   props: {
      settings: {
         type: Object,
         default() {
            return {};
         },
      },
      placeholder: {
         type: String,
         default: "",
         required: false,
      },
   },
   data() {
      return {
         choosedValue: "",
         selectedOption: "",
      };
   },
   methods: {
      toggleOptions(event) {
         if (!this.settings.options.length) return
         event.target.closest(".v-select").classList.toggle("open");
      },
      selectOption(option, notEmit) {
         this.$refs.output.classList.remove("placeholder");
         this.choosedValue = option.name;
         if (notEmit) {
            return
         }
         this.$emit("selectOption", option);
      },
   },
   watch: {
      "$route.params"() {
         if (!this.settings.options) {
            return;
         }
         this.settings.options.forEach((item, index) => {
            if (index == 0) {
               this.selectOption(item);
            }
         });
      },
      settings: {
         handler() {
            this.settings.options.forEach((item) => {
               if (item.selected) {
                  this.selectOption(item);
               }
            });
         },
         deep: true,
      },
   },
   mounted() {
      if (this.settings.placeholder) {
         this.choosedValue = this.settings.placeholder;
      } else {
         if (!this.settings.options?.length) {
            return;
         }
         this.settings.options.forEach((item) => {
            if (item.selected) {
               this.selectOption(item, true);
            }
         });
      }
   },
};
</script>
<style lang="scss">
.v-select {
   cursor: pointer;
   position: relative;
   max-width: fit-content;

   &.open {
      .v-select__backdrop {
         display: block;
      }

      ul {
         opacity: 1;
         pointer-events: all;
      }

      .v-select__icon {
         transform: rotate(180deg);
      }
   }

   &__wrapper {
      display: flex;
      align-items: center;
      gap: 2px;
   }

   &__icon {
      transition: 0.3s;

      svg {
         path {
            stroke: #fff
         }
      }
   }

   &__backdrop {
      // background-color: red;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: none;
   }

   &__input {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      height: 24px;
   }

   &__list {
      padding: 10px;
      border-radius: 8px;
      position: absolute;
      left: 0;
      // top: 24px;
      top: 100%;
      background-color: #2d2f33;
      box-shadow: 0px 0px 19px 4px rgba(0, 0, 0, 0.75);
      width: auto;
      display: flex;
      flex-direction: column;
      gap: 3px;
      z-index: 2;
      opacity: 0;
      pointer-events: none;
      transition: 0.3s;
   }

   &__item {
      white-space: nowrap;
      padding: 10px;
   }
}
</style>
